import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Space } from 'antd';
import api, { GetDraftRes, GetDraftListParameters, DeleteDraftParameters, GetUserParameters, GetUserRes, PostDraftCancelParameters, PostDraftBeginParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import DraftEditAndAdd, { DraftEditAndAddPropsType } from './DraftAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getDraftColumns, DraftColumnData, getApproveStatusInfo } from '../../table-columns/Draft';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import { PlusOutlined } from '@ant-design/icons';

const { confirm } = Modal;

type EditAndAddModalType = DraftEditAndAddPropsType

interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: string
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetDraftRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DraftList extends React.Component<PageProps, PageState> implements Page {


  params: {
    approveStatus?: GetDraftListParameters['approveStatus']
    isAscend?: GetDraftListParameters['isAscend']
  } = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      editAndAddModal: {
        id: '',
        type: 'edit',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<DraftColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getDraftList = async (params: GetDraftListParameters) => {
    const res = await api.GetDraftList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteDraft = async (params: DeleteDraftParameters) => {
    const res = await api.DeleteDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postDraftCancel = async (params: PostDraftCancelParameters) => {
    const res = await api.PostDraftCancel(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postDraftBegin = async (params: PostDraftBeginParameters) => {
    const res = await api.PostDraftBegin(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })

      this.setState({
        isSpinLoading: false,
        initLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }

  _getAddModalTitle = () => {
    switch (this.state.editAndAddModal.type) {
      case 'add': {
        return '新建草稿'
      }
      case 'edit': {
        return '編輯草稿'
      }
      case 'completed': {
        return '審批草稿'
      }
    }
  }
  render() {



    return (
      <div id="DraftList"
      >
        <Modal
          maskClosable={false}
          title={this._getAddModalTitle()}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <DraftEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'add': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                    break
                  case 'completed': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`審批成功`)
                  }
                    break
                }

              }}
            />
          )}

        </Modal>

        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              this.setState(state => ({
                editAndAddModal: {
                  ...state.editAndAddModal,
                  show: true,
                  key: new Date().toString(),
                  type: 'add',
                }
              }))
            }}
            icon={<PlusOutlined />}
          >
            {`添加`}
          </Button>

        </Space>


        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<DraftColumnData, {
                isAscend?: 'true' | 'false'
                approveStatus?: GetDraftListParameters['approveStatus']
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isAscend',
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        key: 'approveStatus',
                        placeholder: '選擇審批狀態',
                        allowClear: true,
                        span: 4,
                        options: [
                          'BEGIN',
                          'COMPLETED',
                          'WAITING',
                          'CANCEL',
                        ].map((item: any) => {
                          return ({
                            value: item,
                            name: getApproveStatusInfo(item).tag,
                            disabled: false,
                          })
                        })
                      },
                    ]
                  })

                }
                columns={getDraftColumns({
                  user: this.props.user,
                  onAction: (key, record) => {
                    switch (key) {

                      case 'completed': {
                        this.setState(state => ({
                          editAndAddModal: { ...state.editAndAddModal, show: true, type: 'completed', id: record.id, key: new Date().toString() }
                        }))
                      }
                        break

                      case 'cancel': {
                        const name = record.name
                        confirm({
                          title: `是否取消審核(${name})`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: async () => {
                            try {
                              await this._postDraftCancel({ id: record.id })
                              this.tableRef && this.tableRef.refreshData()
                              message.success(`${name} 取消審核成功`)
                            } catch (error: any) {
                              message.error(`${name} 取消審核失败 [${error?.message}]`);
                            }
                          },
                          onCancel() {
                          },
                        });
                      }
                        break

                      case 'begin': {
                        const name = record.name
                        confirm({
                          title: `是否恢復準備中(${name})`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: async () => {
                            try {
                              await this._postDraftBegin({ id: record.id })
                              this.tableRef && this.tableRef.refreshData()
                              message.success(`${name} 恢復準備中成功`)
                            } catch (error: any) {
                              message.error(`${name} 恢復準備中失败 [${error?.message}]`);
                            }
                          },
                          onCancel() {
                          },
                        });
                      }
                        break

                      case 'delete': {
                        const name = record.name
                        confirm({
                          title: `是否刪除(${name})`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: async () => {
                            try {
                              await this._deleteDraft({ id: record.id })
                              this.tableRef && this.tableRef.refreshData()
                              message.success(`${name} 删除成功`)
                            } catch (error: any) {
                              message.error(`${name} 删除失败 [${error?.message}]`);
                            }
                          },
                          onCancel() {
                          },
                        });
                      }
                        break
                      case 'edit': {
                        this.setState(state => ({
                          editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                        }))
                      }
                    }
                  }
                })}
                tableProps={{
                  scroll: {
                    x: getDraftColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  }
                }}
                title={'草稿列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body
                  this.params = {}

                  if (sourceItemBody) {
                    this.params.approveStatus = sourceItemBody.approveStatus || undefined
                    if (sourceItemBody.isAscend) {
                      this.params.isAscend = sourceItemBody.isAscend === 'true' ? true : false
                    }
                  }
                  const res = await this._getDraftList({
                    ...body,
                    ...this.params,
                  })
                  tool.route.pushParamsObjHistory(this.params, this.props.history)

                  return {
                    data: await Promise.all(

                      res.data.map(async item => {
                        let createUser: GetUserRes | undefined
                        let approveUser: GetUserRes | undefined

                        createUser = await this._getUser({ id: item.createUserId })
                        if (item.approveUserId) {
                          approveUser = await this._getUser({ id: item.approveUserId })
                        }

                        return ({
                          ...item,
                          createUser,
                          approveUser,
                        })
                      })

                    ),
                    totalCount: res.totalCount,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(DraftList)


import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Button, Modal, Space, Spin } from 'antd';
import api, { GetProductRes, GetProductListParameters, GetUserParameters, GetDraftParameters, GetMachineListParameters, GetMachineRes, GetProductBrandListParameters, GetProductTypeListParameters, GetProductBrandRes, GetProductTypeRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { getProductColumns, ProductColumnData } from '../../table-columns/Product';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import config from '../../config';
import { filterParams, getQueryString } from '../../tool/route';

const { confirm } = Modal;

interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  machines: GetMachineRes[]
  finalPriceDataObj?: ProductColumnData['finalPriceDataObj']
  productBrands: GetProductBrandRes[]
  productTypes: GetProductTypeRes[]
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetProductRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class ProductList extends React.Component<PageProps, PageState> implements Page {


  params: {
    [x: string]: any
  } = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      machines: [],
      productBrands: [],
      productTypes: [],
    }
  }
  tableRef?: JJ_Table<ProductColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getDraft = async (params: GetDraftParameters) => {
    const res = await api.GetDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getProductList = async (params: GetProductListParameters) => {
    const res = await api.GetProductList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getProductTypeList = async (params: GetProductTypeListParameters) => {
    const res = await api.GetProductTypeList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getProductBrandList = async (params: GetProductBrandListParameters) => {
    const res = await api.GetProductBrandList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getMachineList = async (params: GetMachineListParameters) => {
    const res = await api.GetMachineList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })


      const machineListRes = await this._getMachineList({ count: 1000 })
      const productTypeListRes = await this._getProductTypeList({ count: 1000 ,isAscend:false})
      const productBrandListRes = await this._getProductBrandList({ count: 1000 })

      this.setState({
        machines: machineListRes.data,
        isSpinLoading: false,
        initLoading: false,
        productTypes: productTypeListRes.data,
        productBrands: productBrandListRes.data,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="ProductList"
      >

        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              window.open(`${config.REACT_APP_API_URL}/admin/product/?${getQueryString({ ...this.params, dataType: 'EXCEL' })}`)
              // window.open(`${config.REACT_APP_API_URL}/admin/product/?  ${new URLSearchParams({ ...filterParams(this.params), dataType: 'EXCEL' }).toString()}`)
            }}
            icon={<DownloadOutlined />}
          >
            {`導出Excel`}
          </Button>

        </Space>

        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<ProductColumnData, {
                isAscend?: 'true' | 'false'
                machineIds?: GetProductListParameters['machineIds']
                productBrandIds?: GetProductListParameters['productBrandIds']
                productTypeIds?: GetProductListParameters['productTypeIds']
                isEnabled?: 'true' | 'false'
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isAscend',
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        key: 'productBrandIds',
                        placeholder: '選擇產品品牌',
                        allowClear: true,
                        mode: 'multiple',
                        span: 8,
                        options: this.state.productBrands.map(item => ({
                          value: item.kioskProductBrandId,
                          name: item.name,
                          disabled: false,
                        })),
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        key: 'productTypeIds',
                        placeholder: '選擇產品類別',
                        allowClear: true,
                        mode: 'multiple',
                        span: 8,
                        options: this.state.productTypes.map(item => ({
                          value: item.kioskProductTypeId,
                          name: tool.local.formatMessage({ user: this.props.user, data: item.name }),
                          disabled: false,
                        })),
                      },

                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isEnabled',
                        placeholder: '選擇是否啟用',
                        allowClear: true,
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '啟用',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '停用',
                            disabled: false,
                          },
                        ]
                      },

                      {
                        type: 'select',
                        defaultValue: this.state.machines.filter(machine => machine.isEnabled).map(item => item.id),
                        key: 'machineIds',
                        placeholder: '選擇任意機器',
                        allowClear: true,
                        mode: 'multiple',
                        maxTagCount: 12,
                        span: 24,
                        filterOption: (input, option) => {
                          console.log('option', option)
                          return String((option?.children || '')).toLowerCase().includes(input.toLowerCase())
                        },
                        options: this.state.machines.map((machine) => {
                          return ({
                            value: machine.id,
                            name: machine.name,
                            disabled: false,
                          })
                        })
                      },



                    ]
                  })

                }
                columns={getProductColumns({
                  user: this.props.user,
                  finalPriceDataObj: this.state.finalPriceDataObj,
                  productBrands: this.state.productBrands,
                  productTypes: this.state.productTypes,
                })}
                tableProps={{
                  scroll: {
                    x: getProductColumns(
                      {
                        user: this.props.user,
                        finalPriceDataObj: this.state.finalPriceDataObj,
                        productBrands: this.state.productBrands,
                        productTypes: this.state.productTypes,
                      }
                    ).reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  }
                }}
                title={'產品列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody, ...params } = body
                  this.params = { ...params }

                  if (sourceItemBody) {
                    this.params.machineIds = sourceItemBody.machineIds || undefined
                    this.params.productBrandIds = sourceItemBody.productBrandIds || undefined
                    this.params.productTypeIds = sourceItemBody.productTypeIds || undefined
                    if (sourceItemBody.isAscend) {
                      this.params.isAscend = sourceItemBody.isAscend === 'true' ? true : false
                    }

                    if (sourceItemBody.isEnabled) {
                      this.params.isEnabled = sourceItemBody.isEnabled === 'true' ? true : false
                    }

                  }

                  const res = await this._getProductList({
                    ...this.params,
                  })

                  tool.route.pushParamsObjHistory(this.params, this.props.history)

                  return {
                    data: res.data.map(item => {
                      let finalPriceDataObj: ProductColumnData['finalPriceDataObj'] = {}
                      for (const finalPriceData of item.finalPriceDatas) {
                        const machine = this.state.machines.find(item => item.id === finalPriceData.machineId)
                        if (machine) {
                          finalPriceDataObj[finalPriceData.machineId] = {
                            machine,
                            finalPriceData,
                          }
                        }
                      }


                      this.setState({
                        finalPriceDataObj,
                      })
                      return ({
                        ...item,
                        finalPriceDataObj,
                      })
                    }),
                    totalCount: res.totalCount,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(ProductList)


import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetProductBrandRes } from "../api"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"

export type ProductBrandColumnData = GetProductBrandRes & {}

export type ProductBrandColumnParams = {
  user: ActionUserRes
}


export const getProductBrandColumns = (params?: ProductBrandColumnParams): ColumnsType<ProductBrandColumnData> => {


  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },


    {
      title: 'Kiosk 產品品牌ID',
      dataIndex: 'kioskProductBrandId',
      key: 'kioskProductBrandId',
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '名稱',
      dataIndex: ['name'],
      key: 'name',
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}

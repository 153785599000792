import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';


type JJ_FromDecimalInputProps = InputProps & {
  decimal: number
}

export const JJ_FromDecimalInput = (props: JJ_FromDecimalInputProps) => {


  const handleChange = (e) => {
    const { value } = e.target;

    // 使用正则表达式检查输入是否符合小数点两位的格式
    // const regex = /^\d*(\.\d{0,3})?$/;
    const regex = new RegExp(`^\\d*(\\.\\d{0,${props.decimal}})?$`);

    if (regex.test(value) || value === '' || value === '.') {
      props.onChange && props.onChange(value);
    }
  };

  return (
    <Input
      {...props}
      value={props.value}
      onChange={handleChange}
      placeholder="Enter a number with up to two decimal places"
    />
  );
};

import React from 'react';
import { Menu, Spin } from 'antd';
import { Link, Route, RouteChildrenProps, Switch } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ActionUserRes, getUser } from '../../actions/ActionUser';
import { ActionConfigRes, setConfigData } from '../../actions/ActionConfig';
import { getPath } from '../../tool/route';
import api, { GetDraftParameters, GetDraftRes } from '../../api';
import DraftList from '../draft/DraftList';
import DraftProductList from '../draftProduct/DraftProductList';
import DraftMachineList from '../draftMachine/DraftMachineList';
import DraftRecordList from '../draftRecord/DraftRecordList';
import DraftPricingConfig from '../draftPricingConfig/DraftPricingConfig';


export interface PageState {

  loading?: boolean
  draft?: GetDraftRes
  openKeys?: string[]

}

export interface Props {

}



export type PageProps = Props & RouteChildrenProps<{ draftId: string }> & ConnectedProps<typeof connector>



export interface Page {

}


interface RootState {
  user: ActionUserRes
  config: ActionConfigRes
}

const mapState = (state: RootState) => ({
  user: state.user,
  config: state.config,
})


const mapDispatch = {
  setConfigData: setConfigData,
  getUser: getUser,
}

export const connector = connect(
  mapState,
  mapDispatch
)


class Main extends React.Component<PageProps, PageState> implements Page {




  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
    };
  }


  componentWillUnmount() {


    this.setState = () => { }
  }

  componentDidMount() {
    this._initData()

  }




  _getDraft = async (params: GetDraftParameters) => {
    const res = await api.GetDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  async _initData(): Promise<void> {
    try {
      const draftId = this.props.match?.params.draftId

      this.setState({ loading: true })
      const draft = await this._getDraft({ id: draftId || '' })
      this.setState({
        loading: false,
        draft,
      })
    } catch (error: any) {
      this.setState({ loading: false })
    }
  }


  _onOpenChange = (value: string[]) => {
    this.setState({
      openKeys: [value[value.length - 1]],
    })

  };


  render() {
    const draftId = this.props.match?.params.draftId
    const url = this.props.match?.url
    const path = this.props.match?.path
    if (path && url && draftId) {
      const pathname = this.props.location.pathname
      const menus: {
        path: string
        url: string
        title: string
        icon?: JSX.Element
        page?: any
      }[] = [
          {
            path: getPath('/draft/list').key,
            url: getPath('/draft/list').key,
            title: `${this.state.draft?.name || '-'}`
          },
          {
            path: `${path}/draft-product/list`,
            url: `${url}/draft-product/list`,
            title: '產品（草稿）',
            page: DraftProductList,
          },

          {
            path: `${path}/draft-machine/list`,
            url: `${url}/draft-machine/list`,
            title: '機器（草稿）',
            page: DraftMachineList,
          },

          {
            path: `${path}/draft-pricing-config`,
            url: `${url}/draft-pricing-config`,
            title: '定價配置文件（草稿）',
            page: DraftPricingConfig,
          },

          {
            path: `${path}/draft-record/list`,
            url: `${url}/draft-record/list`,
            title: '草稿記錄',
            page: DraftRecordList,
          },

        ];


      return (
        <div id="draftMain">
          <Spin
            spinning={this.state.loading}
          >

            <Menu
              style={{
                marginBottom: 10,
              }}
              selectedKeys={[pathname]}
              openKeys={this.state.openKeys}
              mode="horizontal"
              onOpenChange={(value: any) => this._onOpenChange(value)}
            >
              {menus.map(menu => {
                return (
                  <Menu.Item
                    key={menu.url}
                  >
                    <Link to={menu.url}>
                      {menu.icon}
                      <span>{menu.title}</span>
                    </Link>
                  </Menu.Item>
                )
              })}



            </Menu>

            <Switch>
              {menus.map(item => {
                return (
                  <Route key={item.path} path={item.path} exact component={item.page}></Route>
                )
              })}

              <Route component={DraftList}></Route>

            </Switch>

          </Spin >

        </div >

      )
    }
    return <div />
  }



}
export default connector(Main)

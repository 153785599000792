import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import api, { GetDraftRecordRes, GetDraftRecordListParameters, GetUserParameters, GetDraftMachineRes, GetDraftParameters, GetDraftRes, GetDraftMachineListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { getDraftRecordColumns, DraftRecordColumnData } from '../../table-columns/DraftRecord';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';

const { confirm } = Modal;


interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  draftMachines: GetDraftMachineRes[]
  draft?: GetDraftRes,
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetDraftRecordRes> | null
}


type PageProps = Props & RouteChildrenProps<{ draftId: string }> & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DraftRecordList extends React.Component<PageProps, PageState> implements Page {


  params: {
    isAscend?: GetDraftRecordListParameters['isAscend']
  } = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      draftMachines: [],
    }
  }
  tableRef?: JJ_Table<DraftRecordColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getDraft = async (params: GetDraftParameters) => {
    const res = await api.GetDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDraftRecordList = async (params: GetDraftRecordListParameters) => {
    const res = await api.GetDraftRecordList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDraftMachineList = async (params: GetDraftMachineListParameters) => {
    const res = await api.GetDraftMachineList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })

      const draftId = this.props.match?.params.draftId
      const draft = await this._getDraft({ id: draftId || '' })

      const draftMachineListRes = await this._getDraftMachineList({ draftId: draft.id })

      this.setState({
        draft,
        draftMachines: draftMachineListRes.data,
        isSpinLoading: false,
        initLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="DraftRecordList"
      >
        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<DraftRecordColumnData, {
                isAscend?: 'true' | 'false'
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isAscend',
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },
                    ]
                  })

                }
                columns={getDraftRecordColumns({
                  user: this.props.user,
                })}
                tableProps={{
                  scroll: {
                    x: getDraftRecordColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  }
                }}
                title={'草稿記錄'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body
                  this.params = {}

                  if (sourceItemBody) {
                    if (sourceItemBody.isAscend) {
                      this.params.isAscend = sourceItemBody.isAscend === 'true' ? true : false
                    }
                  }

                  const draft = this.state.draft

                  const res = await this._getDraftRecordList({
                    ...body,
                    ...this.params,
                    draftId: draft?.id || '',
                  })

                  tool.route.pushParamsObjHistory(this.params, this.props.history)
                  return {
                    data: res.data.map(item => {
                      return ({
                        ...item,
                        draft,
                      })
                    }),
                    totalCount: res.totalCount,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(DraftRecordList)


import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetDraftProductListParameters, GetDraftProductParameters, GetOtherProductRes, PostDraftProductParameters, PutDraftProductParameters } from '../../api';
import { Input } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import { JJ_FromDecimalInput } from '../../components/JJ_FromDecimalInput';



interface PageState {

}

export type DraftProductEditAndAddPropsType = ({ type: 'add', draftId: string } & GetOtherProductRes) | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void

}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & DraftProductEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class DraftProductEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }


  _putDraftProduct = async (params: PutDraftProductParameters) => {
    const res = await api.PutDraftProduct(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postDraftProduct = async (params: PostDraftProductParameters) => {
    const res = await api.PostDraftProduct(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDraftProductList = async (params: GetDraftProductListParameters) => {
    const res = await api.GetDraftProductList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getDraftProduct = async (params: GetDraftProductParameters) => {
    const res = await api.GetDraftProduct(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {

        const res = await this._putDraftProduct({
          ...values,
          id: this.props.id,
        })

        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {
        const res = await this._postDraftProduct({
          ...values,
          draftId: this.props.draftId,
        })

        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')

      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getDraftProduct({ id: this.props.id })

        return {
          ...res,
        }

      }
      case 'add': {
        return {
          name: this.props.name,
          code: this.props.code,
          kioskProductId: this.props.kioskProductId,
        }
      }
    }
  };

  _formListData = (): FormItemData[] => {


    switch (this.props.type) {
      case 'edit': {

        return [
          {

            id: 'code',
            label: '唯一碼',
            rules: [
              {
                required: false,
              }
            ],
            componet: <Input disabled={true} />,
          },
          {

            id: 'name',
            label: '名稱',
            rules: [
              {
                required: false,
              }
            ],
            componet: <JJ_FromI18nInputText disabled = {true} />,
          },


          {

            id: 'costPrice',
            label: '成本',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },
          {

            id: 'retailPrice',
            label: '零售價',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [


          {

            id: 'code',
            label: '唯一碼',
            rules: [
              {
                required: true,
              }
            ],
            componet: <Input disabled={true} />,
          },
          {

            id: 'kioskProductId',
            label: '產品ID',
            rules: [
              {
                required: true,
              }
            ],
            componet: <Input disabled={true} />,
          },
          {

            id: 'i18nText',
            name: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
            // componet: <JJ_FromI18nInputText />,
          },


          {

            id: 'costPrice',
            label: '成本',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },
          {

            id: 'retailPrice',
            label: '零售價',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="DraftProductEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}

        />
      </div>
    )
  }

}
export default connector(DraftProductEditAndAdd)

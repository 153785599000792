import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import { Card, Input, message } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import { RouteChildrenProps } from 'react-router-dom';
import api, { GetDraftRes, GetDraftPricingConfigRes, PutDraftPricingConfigParameters, GetDraftPricingConfigParameters, GetDraftParameters } from '../../api';
import JJ_FromTabInputText, { JJ_FromI18nInputTextProps } from '../../components/JJ_FromTabInputText';
import { JJ_FromDecimalInput } from '../../components/JJ_FromDecimalInput';
import tool from '../../tool';



interface PageState {
  draft?: GetDraftRes,
  draftPricingConfig?: GetDraftPricingConfigRes
}

interface Props {


}


interface Page {

}


type PageProps = Props & PropsFromRedux & RouteChildrenProps<{ draftId: string }>

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class DraftPricingConfig extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }


  _putDraftPricingConfig = async (params: PutDraftPricingConfigParameters) => {
    const res = await api.PutDraftPricingConfig(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getDraftPricingConfig = async (params: GetDraftPricingConfigParameters) => {
    const res = await api.GetDraftPricingConfig(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getDraft = async (params: GetDraftParameters) => {
    const res = await api.GetDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _isDisable = () => {
    return !!(this.state.draft?.approveStatus !== 'BEGIN')
  }


  _onFinish = async (values: any): Promise<void> => {
    console.log('_onFinish', values)
    const res = await this._putDraftPricingConfig({
      ...values,
      id: this.state.draftPricingConfig?.id,
      freeCommissionsThreshold: values.freeCommissionsThreshold ? values.freeCommissionsThreshold / 100 : values.freeCommissionsThreshold

    })
    message.success(`編輯成功`)
  }
  _onLoadData = async (): Promise<any> => {

    const draftId = this.props.match?.params.draftId
    if (draftId) {
      const draft = await this._getDraft({ id: draftId })
      const draftPricingConfig = await this._getDraftPricingConfig({ draftId: draft.id })

      this.setState({
        draft,
        draftPricingConfig,
      })

      return {
        ...draftPricingConfig,
        freeCommissionsThreshold: draftPricingConfig.freeCommissionsThreshold ? tool.number.formatNumber(Number(draftPricingConfig.freeCommissionsThreshold) * 100, 2) : draftPricingConfig.freeCommissionsThreshold

      }
    }

  };


  _getTabInputFormItemData = (data: {
    id: FormItemData['id']
    label: FormItemData['label']
    fromI18nInputTextProps: JJ_FromI18nInputTextProps
    required?: boolean
  }): FormItemData => {
    return {
      id: data.id,
      label: data.label,
      componet: <JJ_FromTabInputText {...data.fromI18nInputTextProps} />,
      props: {
        rules: [
          {
            required: data.required,
            validator(rule, value) {
              if (rule.required) {
                if (!value) {
                  return Promise.reject(`请输入${data.label}`)
                }
                let errorTitle: string | undefined
                for (const key in value) {

                  if ((value[key] === '' || value[key] === undefined || value[key] === null) && !errorTitle) {

                    const tabData = data.fromI18nInputTextProps.tabDatas.find(data => data.key === key)

                    errorTitle = tabData?.title
                  }
                }
                return errorTitle
                  ? Promise.reject(`请输入${data.label} 【${errorTitle}】`)
                  : Promise.resolve()
              }
              return Promise.resolve()
            },
          }
        ]
      }

    }
  }

  _formListData = (): FormItemData[] => {

    return [
      this._getTabInputFormItemData({
        id: 'machineSizePricing',
        label: '機器大小定價',
        required: true,
        fromI18nInputTextProps: {
          tabDatas: [
            { key: 'S', title: 'S' },
            { key: 'M', title: 'M' },
            { key: 'L', title: 'L' },
          ],
          inputProps: { type: 'number', disabled: this._isDisable() },
        }
      }),


      this._getTabInputFormItemData({
        id: 'distancePricing',
        label: '場地距離定價',
        required: true,
        fromI18nInputTextProps: {
          tabDatas: [
            { key: 'LAN', title: 'LAN' },
            { key: 'HK', title: 'HK' },
            { key: 'KLN', title: 'KLN' },
            { key: 'NT', title: 'NT' },
          ],
          inputProps: { type: 'number', disabled: this._isDisable() },
        }
      }),

      this._getTabInputFormItemData({
        id: 'tollPricing',
        label: '場地通行費定價',
        required: true,
        fromI18nInputTextProps: {
          tabDatas: [
            { key: 'LAN', title: 'LAN' },
            { key: 'HK', title: 'HK' },
            { key: 'KLN', title: 'KLN' },
            { key: 'NT', title: 'NT' },
          ],
          inputProps: { type: 'number', disabled: this._isDisable() },
        }
      }),

      {
        id: 'freeCommissionsThreshold',
        label: '佣金',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <JJ_FromDecimalInput type='number' decimal={2} disabled={this._isDisable()} />
        )
      },

      {
        id: 'defaultRetailPriceMarkup',
        label: '默認零售價加成',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <JJ_FromDecimalInput type='number' decimal={2} disabled={this._isDisable()} />
        )
      },
      ...(this._isDisable() ? [] : [
        {
          id: 'submit',
        }
      ]),
    ]
  }


  render() {
    return (
      <Card
        title={'定價配置文件（草稿）'}
      >
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />

      </Card>
    )
  }

}
export default connector(DraftPricingConfig)

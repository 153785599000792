import { ColumnsType } from "antd/lib/table"
import { Image, Tag } from 'antd'
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { finalPriceData, GetMachineRes, GetProductBrandRes, GetProductRes, GetProductTypeRes } from "../api"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import tool from "../tool"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"
import JJ_TableItemMoment from "../components/JJ_TableItemMoment"



export type ProductColumnData = GetProductRes & {
  finalPriceDataObj?: {
    [machineId: string]: {
      machine: GetMachineRes
      finalPriceData: finalPriceData
    }
  }
}
type ProductColumnParamOnActionKey = 'edit' | 'delete'

export type ProductColumnParams = {
  user: ActionUserRes
  finalPriceDataObj?: {
    [machineId: string]: {
      machine: GetMachineRes
      finalPriceData: finalPriceData
    }
  }
  productBrands: GetProductBrandRes[]
  productTypes: GetProductTypeRes[]
}


export const getProductColumns = (params?: ProductColumnParams): ColumnsType<ProductColumnData> => {


  const getFinalPriceDataList = () => {

    const finalPriceDataObj = params?.finalPriceDataObj
    if (finalPriceDataObj) {
      return Object.keys(finalPriceDataObj).map(machineId => {
        const item = finalPriceDataObj[machineId]
        return {
          title: item.machine.name,
          dataIndex: machineId,
          key: machineId,
          width: 150,
          onCell: (record) => {


            if (record.finalPriceDataObj && record.finalPriceDataObj[machineId]) {

              const showRed = record?.finalPriceDataObj[machineId]?.finalPriceData?.finalPrice === 999
              const backgroundColor = showRed ? 'rgba(230,135,135,0.05)' : undefined
              return {
                style: { backgroundColor }
              }

            }
            return {}


          },


          render: (value, record: ProductColumnData) => {
            if (record.finalPriceDataObj && record.finalPriceDataObj[machineId]) {

              const showRed = record?.finalPriceDataObj[machineId]?.finalPriceData?.finalPrice === 999


              return showRed ? '--' : tool.number.formatNumber(record.finalPriceDataObj[machineId].finalPriceData.finalPrice, 1)

            }
          },

        }
      })
    }
    return []

  }


  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      fixed: 'left',
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },


    {
      title: '唯一碼',
      dataIndex: 'code',
      key: 'code',
      width: 120,
      fixed: 'left',
    },

    {
      title: '名稱',
      dataIndex: ['name'],
      key: 'name',
      width: 200,
      fixed: 'left',
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.name }),

    },


    {
      title: <div style={{ color: tool.color.primary_color }}>零售價</div>,
      dataIndex: ['retailPrice'],
      key: 'retailPrice',
      width: 100,
      fixed: 'left',
      render: (value, record) => tool.number.formatNumber(value, 1),
    },

    {
      title: '圖片',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 70,
      fixed: 'left',

      render: (value, record) => (
        <Image
          width={60}
          src={record.imageUrl || ''}
          preview={true}
        />
      )
    },

    {
      title: '成本',
      dataIndex: ['costPrice'],
      key: 'costPrice',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 1),

    },


    {
      title: '產品品牌',
      dataIndex: 'kioskProductBrandId',
      key: 'kioskProductBrandId',
      width: 150,
      render: (value, record) => {
        const breand = params?.productBrands.find(breand => breand.kioskProductBrandId === record.kioskProductBrandId)
        return breand?.name
      }
    },

    {
      title: '產品類別',
      dataIndex: 'kioskProductTypeId',
      key: 'kioskProductTypeId',
      width: 150,
      render: (value, record) => {
        const productType = params?.productTypes.find(productType => productType.kioskProductTypeId === record.kioskProductTypeId)
        return tool.local.formatMessage({ user: params?.user, data: productType?.name })
      }
    },

    {
      title: '是否啟用',
      dataIndex: ['isEnabled'],
      key: 'isEnabled',
      width: 100,
      render: (value, record) => {
        return (
          record.isEnabled
            ? <Tag color="blue">啟用</Tag>
            : <Tag color="red">停用</Tag>
        )
      }
    },

    ...(getFinalPriceDataList()),

    {
      title: '創建時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (value) => (
        <JJ_TableItemMoment
          date={value}
        />
      )
    },
  ]
}

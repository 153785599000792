import React, { } from 'react';
import api, { GetDraftProductListParameters, GetProductBrandListParameters, GetProductBrandRes, GetProductListParameters, GetProductRes, GetProductTypeListParameters, GetProductTypeRes } from '../../api';
import { Modal, Spin, Image, Tag, Space, Button } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import config from '../../config';
import { getQueryString } from '../../tool/route';
import JJ_TableHeader from '../../components/JJ_TableHeader';
import tool from '../../tool';
import { ActionUserRes } from '../../actions/ActionUser';
import JJ_TableTransfer from '../../components/JJ_TableTransfer';
import { ColumnsType } from 'antd/lib/table';
import { DownloadOutlined } from '@ant-design/icons';





interface PageState {

  productBrandList: GetProductBrandRes[]
  productTypeList: GetProductTypeRes[]
  productList: GetProductRes[]
  selelctProductIds: string[]
  initLoading?: boolean

  filterOption: {
    productBrandIds: GetProductListParameters['productBrandIds']
    productTypeIds: GetProductListParameters['productTypeIds']
  }

}

export type MachineExportPdfModalPropsType = {
  machineId: string
  title?: string
  visible?: boolean
  onCancel?(): void
  onFinish?(url: string): void
  isDraft?: boolean
  draftId?: string
  user: ActionUserRes
}


type Props = {

} & MachineExportPdfModalPropsType



interface Page {


}


type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class MachineExportPdfModal extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
      productBrandList: [],
      productTypeList: [],
      productList: [],
      selelctProductIds: [],
      filterOption: {
        productBrandIds: [],
        productTypeIds: [],
      }
    }

  }
  componentDidMount() {
    this._onLoadData()
  }


  _getProductBrandList = async (params: GetProductBrandListParameters) => {
    const res = await api.GetProductBrandList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getProductTypeList = async (params: GetProductTypeListParameters) => {
    const res = await api.GetProductTypeList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getProductList = async (params: GetProductListParameters) => {
    const res = await api.GetProductList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getDraftProductList = async (params: GetDraftProductListParameters) => {
    const res = await api.GetDraftProductList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }




  _onLoadData = async (): Promise<any> => {

    const productBrandList = (await this._getProductBrandList({ count: 10000 })).data
    const productTypeList = (await this._getProductTypeList({ count: 10000 ,isAscend:false})).data
    this.setState({
      productBrandList,
      productTypeList,
    })

    await this._initChangeData()
  }




  _changeTimeout?: any


  _initChangeData = async () => {


    this.setState({
      initLoading: true
    })



    let productList: GetProductRes[] = []

    if (this.props.isDraft && this.props.draftId) {
      const productListRes = await this._getDraftProductList({ count: 100000, isEnabled: true, draftId: this.props.draftId })
      productList = productListRes.data
    } else {
      const productListRes = await this._getProductList({ count: 100000, isEnabled: true })

      productList = productListRes.data
    }



    this.setState({
      initLoading: false,
      productList,
      selelctProductIds: [],
    })

  }



  _onChange = async (key: string, value: any) => {

    this.setState({
      filterOption: {
        ...this.state.filterOption,
        [key]: value,
      }
    })

  }


  _getColumuns = () => {
    const columns: ColumnsType<GetProductRes> = [
      {
        title: '名稱',
        dataIndex: ['name'],
        key: 'name',
        width: 200,
        fixed: 'left',
        render: (value, record) => tool.local.formatMessage({ user: this.props?.user, data: record.name }),
      },
      {
        title: '圖片',
        dataIndex: 'imageUrl',
        key: 'imageUrl',
        width: 70,
        render: (value, record) => (
          <Image
            width={60}
            src={record.imageUrl || ''}
            preview={true}
          />
        )
      },

      {
        title: '是否啟用',
        dataIndex: ['isEnabled'],
        key: 'isEnabled',
        width: 100,
        render: (value, record) => {
          return (
            record.isEnabled
              ? <Tag color="blue">啟用</Tag>
              : <Tag color="red">停用</Tag>
          )
        }
      },
    ]
    return columns
  }


  _onFinish = (isFinalPrice: boolean) => {

    const values: {
      productIds: string[]
      isDraft?: boolean
      //显示最终价格
      isFinalPrice?: boolean
    } = {
      productIds: this.state.selelctProductIds,
      isDraft: this.props.isDraft,
      isFinalPrice: isFinalPrice
    }

    this.props.onFinish && this.props.onFinish(`${config.REACT_APP_API_URL}/admin/machine/${this.props.machineId}/product-pdf/?${getQueryString(values)}`)

  }


  _getLeftDataSource = () => {
    const { productList, filterOption, selelctProductIds } = this.state;
    // 返回未在 targetKeys 中且符合筛选条件的项
    return productList.filter(item => {


      if (filterOption.productBrandIds?.length || filterOption.productTypeIds?.length) {

        return selelctProductIds.some(id => item.id === id) || filterOption.productBrandIds?.some(id => item.kioskProductBrandId === id) || filterOption.productTypeIds?.some(id => item.kioskProductTypeId === id)


      } else {

        return productList

      }



    }
    );
  };

  render() {
    const leftDataSource = this._getLeftDataSource();

    return (
      <div id="MachineExportPdfModal">
        <Modal
          maskClosable={false}
          title={this.props.title}
          visible={this.props.visible}
          footer={null}
          onCancel={() => this.props.onCancel && this.props.onCancel()}
          width={'85%'}
        >

          <Spin spinning={this.state.initLoading}>
            <JJ_TableHeader
              isSearchText={false}
              onSourceItemData={this._onChange}
              onSearchText={value => this._onChange('searchText', value)}
              sourceItems={
                [

                  {
                    type: 'select',
                    defaultValue: undefined,
                    key: 'productBrandIds',
                    placeholder: '選擇產品品牌',
                    allowClear: true,
                    mode: 'multiple',
                    span: 8,
                    options: this.state.productBrandList.map(item => ({
                      value: item.kioskProductBrandId,
                      name: item.name,
                      disabled: false,
                    })),
                    filterOption: (input, option) => {
                      return String((option?.children || '')).toLowerCase().includes(input.toLowerCase())
                    },
                  },

                  {
                    type: 'select',
                    defaultValue: undefined,
                    key: 'productTypeIds',
                    placeholder: '選擇產品類別',
                    allowClear: true,
                    mode: 'multiple',
                    span: 8,
                    options: this.state.productTypeList.map(item => ({
                      value: item.kioskProductTypeId,
                      name: tool.local.formatMessage({ user: this.props.user, data: item.name }),
                      disabled: false,
                    })),
                    filterOption: (input, option) => {
                      return String((option?.children || '')).toLowerCase().includes(input.toLowerCase())
                    },
                  },
                ]
              }
            />


            <JJ_TableTransfer
              transferProps={{
                titles: ['未選擇產品', '已選擇產品'],
                listStyle: {
                },
                dataSource: leftDataSource.map(product => ({
                  ...product,
                  key: product.id,
                })),
                targetKeys: this.state.selelctProductIds,
                showSearch: false,
                onChange: targetKeys => this.setState({ selelctProductIds: targetKeys }),
              }}
              leftColumns={this._getColumuns()}
              rightColumns={this._getColumuns()}
              tableProps={{
                scroll: { y: 400 },
                pagination: {
                  pageSizeOptions: ['100', '200', '500', '1000'],
                  defaultPageSize: 200,
                }
              }}
            />

            <div
              style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
              <Space
                align='end'
                style={{ marginTop: 10 }}
              >

                <Button
                  disabled={!(this.state.selelctProductIds.length > 0)}
                  type='primary'
                  style={{ marginBottom: 5 }}
                  onClick={() => this._onFinish(false)}
                  icon={<DownloadOutlined />}
                >
                  {`導出`}
                </Button>

                <Button
                  disabled={!(this.state.selelctProductIds.length > 0)}
                  type='primary'
                  style={{ marginBottom: 5 }}
                  onClick={() => this._onFinish(true)}
                  icon={<DownloadOutlined />}
                >
                  {`導出（含價格）`}
                </Button>

              </Space>
            </div>



          </Spin>


        </Modal>


      </div>
    )
  }

}
export default connector(MachineExportPdfModal)

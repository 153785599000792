import React, { } from 'react';
import { Form, Input, Button, Card, message, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { PageProps, PageState, Page } from "./Login.interface";
import api from '../../api';
import tool from '../../tool';


export default class Login extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount() {

  }

  onFinish = async (values: any) => {
    try {
      this.setState({ loading: true })
      const res = await api.PostAuthPasswordLogin(values)
      if (res.kind !== 'ok') throw new Error(res.kind)
      message.success('登入成功')
      this.setState({ loading: false })
      this.props.history.push(tool.route.getPath('/product/list').key)

    } catch (error:any) {
      console.log(error)
      this.setState({ loading: false })
    }
  };

  render() {
    return (
      <div id="login">
        <Spin spinning={this.state.loading}>
          <Card title="請先登錄"
            className='login-card'
          >
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Log in
              </Button>
              </Form.Item>
            </Form>
          </Card>

        </Spin>


      </div>
    )
  }

}

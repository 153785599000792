import React from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetDraftMachineListParameters, GetDraftMachineParameters, GetOtherMachineRes, PostDraftMachineParameters, PutDraftMachineParameters } from '../../api';
import { Input, Select } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';
import { JJ_FromDecimalInput } from '../../components/JJ_FromDecimalInput';



interface PageState {

}

export type DraftMachineEditAndAddPropsType = ({ type: 'add', draftId: string } & GetOtherMachineRes) | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void

}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & DraftMachineEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class DraftMachineEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }


  _putDraftMachine = async (params: PutDraftMachineParameters) => {
    const res = await api.PutDraftMachine(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postDraftMachine = async (params: PostDraftMachineParameters) => {
    const res = await api.PostDraftMachine(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDraftMachineList = async (params: GetDraftMachineListParameters) => {
    const res = await api.GetDraftMachineList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getDraftMachine = async (params: GetDraftMachineParameters) => {
    const res = await api.GetDraftMachine(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {

        const res = await this._putDraftMachine({
          ...values,
          id: this.props.id,
          commissions: values.commissions ? values.commissions / 100 : values.commissions
        })

        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {
        const res = await this._postDraftMachine({
          ...values,
          draftId: this.props.draftId,
          commissions: values.commissions ? values.commissions / 100 : values.commissions
        })

        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')

      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getDraftMachine({ id: this.props.id })

        return {
          ...res,
          commissions: res.commissions ? Number(res.commissions) * 100 : res.commissions
        }

      }
      case 'add': {
        return {
          isEnabled: true,
          name: this.props.name,
          shopNo: this.props.shopNo,
          kioskId: this.props.kioskId,

        }
      }
    }
  };

  _formListData = (): FormItemData[] => {


    switch (this.props.type) {
      case 'edit': {

        return [

          {
            id: 'shopNo',
            label: '唯一碼',
            rules: [
              {
                required: false,
              }
            ],
            componet: <Input disabled={true} />,
          },

          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: false,
              }
            ],
            componet: <Input disabled={true} />,
          },
       

          {
            id: 'region',
            label: '區域',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Select>
                {['LAN', 'HK', 'NT', 'KLN'].map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            )
          },

          {
            id: 'operator',
            label: '公司',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'commissions',
            label: '佣金/手續費',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },

          {
            id: 'parkingRefill',
            label: '停車/加油',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },

          {
            id: 'size',
            label: '大小',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Select>
                {['S', 'M', 'L'].map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            )
          },

          {
            id: 'other',
            label: '其他',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },

          {
            id: 'priceMultiplier',
            label: '最終價格乘數',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={2} />
            )
          },

          {
            id: 'isEnabled',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [

          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'shopNo',
            label: '唯一碼',
            rules: [
              {
                required: true,
              }
            ],
            componet: <Input disabled={true} />,
          },

          {
            id: 'kioskId',
            label: '機器Id',
            rules: [
              {
                required: true,
              }
            ],
            componet: <Input disabled={true} />,
          },

          {
            id: 'region',
            label: '區域',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Select>
                {['LAN', 'HK', 'NT', 'KLN'].map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            )
          },

          {
            id: 'operator',
            label: '公司',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'commissions',
            label: '佣金/手續費',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },
          {
            id: 'parkingRefill',
            label: '停車/加油',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },

          {
            id: 'size',
            label: '大小',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Select>
                {['S', 'M', 'L'].map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>{item}</Select.Option>
                  )
                })}
              </Select>
            )
          },

          {
            id: 'other',
            label: '其他',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={2} />
            )
          },
          {
            id: 'priceMultiplier',
            label: '最終價格乘數',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <JJ_FromDecimalInput type='number' decimal={1} />
            )
          },
          {
            id: 'isEnabled',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },

          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="DraftMachineEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}

        />
      </div>
    )
  }

}
export default connector(DraftMachineEditAndAdd)

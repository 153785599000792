import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import api, { GetUserParameters, GetMachineRes, GetDraftParameters, GetMachineListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { getMachineColumns, MachineColumnData } from '../../table-columns/Machine';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import MachineExportPdfModal from './MachineExportPdfModal';

const { confirm } = Modal;


interface PageState {
  initLoading: boolean
  isSpinLoading: boolean


  machineExportPdfModalProps?: {
    key?: any
    machineId?: string
    title?: string
    visible?: boolean
    onCancel?(): void
    onFinish?(url: string): void
  }

}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetMachineRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class MachineList extends React.Component<PageProps, PageState> implements Page {


  params: {
    [x: string]: any
  } = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
    }
  }
  tableRef?: JJ_Table<MachineColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getDraft = async (params: GetDraftParameters) => {
    const res = await api.GetDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getMachineList = async (params: GetMachineListParameters) => {
    const res = await api.GetMachineList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })

      this.setState({
        isSpinLoading: false,
        initLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="MachineList"
      >
        {this.state.machineExportPdfModalProps?.key && this.state.machineExportPdfModalProps?.machineId && (
          <MachineExportPdfModal
            isDraft={false}
            user={this.props.user}
            key={this.state.machineExportPdfModalProps.key}
            machineId={this.state.machineExportPdfModalProps.machineId}
            visible={this.state.machineExportPdfModalProps.visible}
            title={'導出PDF'}
            onCancel={() => {
              this.setState({
                machineExportPdfModalProps: {
                  ...this.state.machineExportPdfModalProps || {},
                  visible: false,
                }
              })
            }}
            onFinish={(url) => {
              console.log('url', url)
              window.open(url)
              this.setState({
                machineExportPdfModalProps: {
                  ...this.state.machineExportPdfModalProps || {},
                  // visible: false,
                }
              })
            }}
          />
        )}


        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<MachineColumnData, {
                isAscend?: 'true' | 'false'
                isEnabled?: 'true' | 'false'

              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'false',
                        key: 'isAscend',
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },
                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isEnabled',
                        placeholder: '選擇是否啟用',
                        allowClear: true,
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '啟用',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '停用',
                            disabled: false,
                          },
                        ]
                      },
                    ]
                  })

                }
                columns={getMachineColumns({
                  user: this.props.user,

                  onAction: (key, record) => {
                    switch (key) {
                      case 'PDF': {
                        this.setState({
                          machineExportPdfModalProps: {
                            visible: true,
                            key: Date.now(),
                            machineId: record.id,
                          }
                        })
                      }
                        break

                    }
                  },

                })}
                tableProps={{
                  scroll: {
                    x: getMachineColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  },
                  onRow: (record) => {

                    return {
                      style: {
                        backgroundColor: record.size ? undefined : 'rgba(230,135,135,0.1)',
                      }
                    }
                  }
                }}
                title={'機器列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body
                  this.params = {}

                  if (sourceItemBody) {

                    if (sourceItemBody.isAscend) {
                      this.params.isAscend = sourceItemBody.isAscend === 'true' ? true : false
                    }

                    if (sourceItemBody.isEnabled) {
                      this.params.isEnabled = sourceItemBody.isEnabled === 'true' ? true : false
                    }

                  }
                  const res = await this._getMachineList({
                    ...body,
                    ...this.params,
                  })

                  tool.route.pushParamsObjHistory(this.params, this.props.history)
                  return {
                    data: res.data,
                    totalCount: res.totalCount,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(MachineList)


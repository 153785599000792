import React, { useState } from 'react';
import { Button, Descriptions, Modal } from 'antd';
import api, { GetOtherMachineParameters, GetOtherMachineRes, GetOtherProductParameters, GetOtherProductRes } from '../../api';
import JJ_From, { FormItemData } from '../../components/JJ_From';



export type ModalOtherProps = {
  visible?: boolean
  onCancel?(): void
  title: string
} &
  (
    {
      type: 'machine'
      onFinish?(data: GetOtherMachineRes): void

    } |
    {
      type: 'product'
      onFinish?(data: GetOtherProductRes): void
    }
  )
type PageState = {

}

export class ModalOther extends React.Component<ModalOtherProps, PageState>  {


  constructor (props: Readonly<ModalOtherProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }


  _getOtherProduct = async (params: GetOtherProductParameters) => {
    const res = await api.GetOtherProduct(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getOtherMachine = async (params: GetOtherMachineParameters) => {
    const res = await api.GetOtherMachine(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {

      case 'product': {
        const res = await this._getOtherProduct({
          ...values,
        })

        this.props.onFinish && this.props.onFinish(res)

      }
        break

      case 'machine': {
        const res = await this._getOtherMachine({
          ...values,
        })

        this.props.onFinish && this.props.onFinish(res)

      }
        break

    }
  }

  _onLoadData = async (): Promise<any> => {

  };



  _formListData = (): FormItemData[] => {


    switch (this.props.type) {
      case 'product': {
        return [
          {
            id: 'code',
            label: '產品唯一碼',
            rules: [
              {
                required: false,
                message: `請輸入 產品唯一碼`
              }
            ],
          },

          {
            id: 'submit',
          },
        ]
      }
      case 'machine': {

        return [
          {
            id: 'shopNo',
            label: '機器唯一碼',
            rules: [
              {
                required: false,
                message: `請輸入 機器唯一碼`
              }
            ],
          },

          {
            id: 'submit',
          },
        ]
      }
    }
  }

  render() {

    return (
      <>
        <Modal
          maskClosable={false}
          title={this.props.title}
          visible={this.props.visible}
          footer={null}
          onCancel={this.props.onCancel}
          width={'85%'}
        >

          <JJ_From
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}

          />

        </Modal>
      </>
    )

  }

}


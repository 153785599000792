import React, { } from 'react';
import { Input, Col, DatePicker, Radio, Row, Select, Switch } from 'antd';
import { SourceItem } from './JJ_Table';

interface PageState {

}


interface Props {
  title?: string
  isSearchText?: boolean 
  onSourceItemData?(key: string, value: any): void
  onSearchText?(value: string): void
  sourceItems?: (SourceItem)[]

}

type PageProps = Props

interface Page {

}

export default class JJ_TableHeader extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }

  _onChange = async (key: string, value: any) => {
    this.props.onSourceItemData && this.props.onSourceItemData(key, value)
  }


  _sourceItems = (sourceItems: SourceItem[]) => {


    const getSourceItemView = (item: SourceItem) => {

      switch (item.type) {
        case 'switch': {
          return (
            <Col
              key={item.key}
              className="gutter-row" span={item.span || 4}
            >
              <Switch
                style={{ width: '100%' }}
                defaultChecked={item.defaultValue}
                disabled={item.disabled}
                size={item.size}
                checkedChildren={item.checkedChildren}
                unCheckedChildren={item.unCheckedChildren}
                onChange={e => {
                  this._onChange(item.key, e)
                }}
              />

            </Col>
          )
        }
        case 'select': {
          const { options, ...props } = item
          return (
            <Col
              key={item.key}
              className="gutter-row" span={item.span || 4}
            >
              <Select
                {...props}
                style={{ width: '100%' }}
                allowClear={item.allowClear}
                defaultValue={item.defaultValue}
                placeholder={item.placeholder}
                dropdownMatchSelectWidth={false}
                filterOption={item.filterOption}
                showSearch={item.showSearch}
                mode={item.mode}
                onChange={e => {
                  this._onChange(item.key, e)
                }}
              >
                {item.options.map((item, index) => {
                  return (
                    <Select.Option
                      key={index}
                      disabled={item.disabled}
                      value={item.value}>{item.name}</Select.Option>
                  )
                })}
              </Select>
            </Col>
          )
        }
        case 'radioGroup': {
          return (
            <Col
              key={item.key}
              className="gutter-row" span={item.span || 4}
            >
              <Radio.Group
                style={{ width: '100%' }}
                defaultValue={item.defaultValue}
                onChange={e => {
                  this._onChange(item.key, e.target.value)
                }}
              >

                {item.options.map((item, index) => {
                  return (
                    <Radio.Button
                      key={index}
                      disabled={item.disabled}
                      value={item.value}
                    >{item.name}</Radio.Button>
                  )
                })}
              </Radio.Group>
            </Col>

          )
        }

        case 'input': {
          return (
            <Col
              key={item.key}
              className="gutter-row" span={item.span || 4}
            >

              <Input
                style={{ width: '100%' }}
                {...item.props}
                defaultValue={item.defaultValue}
                onChange={e => {
                  this._onChange(item.key, e.target.value)
                }}
              />
            </Col>
          )
        }

        case 'rangePicker': {
          return (
            <Col
              key={item.key}
              className="gutter-row" span={item.span || 4}
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                {...item.props}
                defaultValue={item.defaultValue}
                onChange={e => {
                  this._onChange(item.key, e)
                }}
              />
            </Col>
          )
        }
        case 'datePicker': {
          return (
            <Col
              key={item.key}
              className="gutter-row" span={item.span || 4}
            >
              <DatePicker
                style={{ width: '100%' }}
                {...item.props}
                defaultValue={item.defaultValue}
                onChange={e => {
                  this._onChange(item.key, e)
                }}
              />
            </Col>
          )
        }

      }

    }
    return (
      <Row gutter={[12, 12]} style={{ marginTop: 8 }}>
        {sourceItems.map(item => {
          return getSourceItemView(item)
        })}
      </Row>
    )
  }


  render() {
    return (
      <>
        {this.props.title && (<h2 style={{ padding: '10px,0px' }}>{this.props.title}</h2>)}
        {this.props.isSearchText && (
          <Input.Search
            onSearch={value => {
              this.props.onSearchText && this.props.onSearchText(value)
            }}
          />
        )}
        {this.props.sourceItems && this._sourceItems(this.props.sourceItems)}
      </>
    )

  }

}

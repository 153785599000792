import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Space } from 'antd';
import api, { GetDraftProductRes, GetDraftProductListParameters, DeleteDraftProductParameters, GetUserParameters, GetDraftMachineRes, GetDraftParameters, GetDraftRes, GetDraftMachineListParameters, GetProductBrandRes, GetProductTypeRes, GetProductBrandListParameters, GetProductTypeListParameters, GetProductListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import DraftProductEditAndAdd, { DraftProductEditAndAddPropsType } from './DraftProductAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getDraftProductColumns, DraftProductColumnData } from '../../table-columns/DraftProduct';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import { ModalOther, ModalOtherProps } from '../other/ModalOther';
import config from '../../config';
import { getQueryString } from '../../tool/route';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';


const { confirm } = Modal;

type EditAndAddModalType = DraftProductEditAndAddPropsType

interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: string
  } & EditAndAddModalType
  draftMachines: GetDraftMachineRes[]
  draft?: GetDraftRes,
  finalPriceDataObj?: DraftProductColumnData['finalPriceDataObj']

  modalOther?: {
    key?: any
  } & ModalOtherProps,
  productBrands: GetProductBrandRes[]
  productTypes: GetProductTypeRes[]
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetDraftProductRes> | null
}


type PageProps = Props & RouteChildrenProps<{ draftId: string }> & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DraftProductList extends React.Component<PageProps, PageState> implements Page {


  params: any = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      draftMachines: [],
      editAndAddModal: {
        id: '',
        type: 'edit',
        show: false,
        key: new Date().toString()
      },
      productBrands: [],
      productTypes: [],
    }
  }
  tableRef?: JJ_Table<DraftProductColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getDraft = async (params: GetDraftParameters) => {
    const res = await api.GetDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDraftProductList = async (params: GetDraftProductListParameters) => {
    const res = await api.GetDraftProductList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDraftMachineList = async (params: GetDraftMachineListParameters) => {
    const res = await api.GetDraftMachineList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getProductTypeList = async (params: GetProductTypeListParameters) => {
    const res = await api.GetProductTypeList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getProductBrandList = async (params: GetProductBrandListParameters) => {
    const res = await api.GetProductBrandList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteDraftProduct = async (params: DeleteDraftProductParameters) => {
    const res = await api.DeleteDraftProduct(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _isDisable = () => {
    return !!(this.state.draft?.approveStatus !== 'BEGIN')
  }

  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })

      const draftId = this.props.match?.params.draftId
      const draft = await this._getDraft({ id: draftId || '' })

      const draftMachineListRes = await this._getDraftMachineList({ draftId: draft.id, count: 1000, })

      const productTypeListRes = await this._getProductTypeList({ count: 10000, isAscend: false })
      const productBrandListRes = await this._getProductBrandList({ count: 1000 })

      this.setState({
        draft,
        draftMachines: draftMachineListRes.data,
        isSpinLoading: false,
        initLoading: false,
        productTypes: productTypeListRes.data,
        productBrands: productBrandListRes.data,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="DraftProductList"
      >


        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              window.open(`${config.REACT_APP_API_URL}/admin/draft-product/?${getQueryString({ ...this.params, dataType: 'EXCEL' })}`)
            }}
            icon={<DownloadOutlined />}
          >
            {`導出Excel`}
          </Button>

        </Space>


        {this.state.modalOther && (
          <ModalOther
            {...this.state.modalOther}
          />
        )}


        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯產品（草稿）' : '新建產品（草稿）'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <DraftProductEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'add': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>


        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<DraftProductColumnData, {
                isAscend?: 'true' | 'false'
                draftMachineIds?: GetDraftProductListParameters['draftMachineIds']
                productBrandIds?: GetProductListParameters['productBrandIds']
                productTypeIds?: GetProductListParameters['productTypeIds']
                isEnabled?: 'true' | 'false'
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isAscend',
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        key: 'productBrandIds',
                        placeholder: '選擇產品品牌',
                        allowClear: true,
                        mode: 'multiple',
                        span: 8,
                        options: this.state.productBrands.map(item => ({
                          value: item.kioskProductBrandId,
                          name: item.name,
                          disabled: false,
                        })),
                      },

                      {
                        type: 'select',
                        defaultValue: undefined,
                        key: 'productTypeIds',
                        placeholder: '選擇產品類別',
                        allowClear: true,
                        mode: 'multiple',
                        span: 8,
                        options: this.state.productTypes.map(item => ({
                          value: item.kioskProductTypeId,
                          name: tool.local.formatMessage({ user: this.props.user, data: item.name }),
                          disabled: false,
                        })),
                      },

                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isEnabled',
                        placeholder: '選擇是否啟用',
                        allowClear: true,
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '啟用',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '停用',
                            disabled: false,
                          },
                        ]
                      },

                      {
                        type: 'select',
                        defaultValue: this.state.draftMachines.filter(machine => machine.isEnabled).map(item => item.id),
                        key: 'draftMachineIds',
                        placeholder: '選擇任意機器',
                        allowClear: true,
                        mode: 'multiple',
                        span: 24,
                        maxTagCount: 12,
                        filterOption: (input, option) => {
                          console.log('option', option)
                          return String((option?.children || '')).toLowerCase().includes(input.toLowerCase())
                        },
                        options: this.state.draftMachines.map((machine) => {
                          return ({
                            value: machine.id,
                            name: machine.name,
                            disabled: false,
                          })
                        })
                      },
                    ]
                  })

                }
                columns={getDraftProductColumns({
                  productBrands: this.state.productBrands,
                  productTypes: this.state.productTypes,
                  finalPriceDataObj: this.state.finalPriceDataObj,
                  user: this.props.user,
                  onAction: (key, record) => {
                    const name = tool.local.formatMessage({ user: this.props.user, data: record.name })
                    switch (key) {
                      case 'delete': {
                        confirm({
                          title: `是否刪除(${name})`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: async () => {
                            try {
                              await this._deleteDraftProduct({ id: record.id })
                              this.tableRef && this.tableRef.refreshData()
                              message.success(`${name} 删除成功`)
                            } catch (error: any) {
                              message.error(`${name} 删除失败 [${error?.message}]`);
                            }
                          },
                          onCancel() {
                          },
                        });
                      }
                        break
                      case 'edit': {
                        this.setState(state => ({
                          editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                        }))
                      }
                    }
                  }
                })}
                tableProps={{
                  scroll: {
                    x: getDraftProductColumns({
                      productBrands: this.state.productBrands,
                      productTypes: this.state.productTypes,
                      finalPriceDataObj: this.state.finalPriceDataObj,
                      user: this.props.user,
                    }).reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  }
                }}
                title={'產品（草稿）列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody, ...params } = body
                  const draft = this.state.draft

                  this.params = {
                    ...params,
                    draftId: draft?.id || '',
                  }

                  if (sourceItemBody) {
                    this.params.draftMachineIds = sourceItemBody.draftMachineIds || undefined
                    this.params.productBrandIds = sourceItemBody.productBrandIds || undefined
                    this.params.productTypeIds = sourceItemBody.productTypeIds || undefined

                    if (sourceItemBody.isAscend) {
                      this.params.isAscend = sourceItemBody.isAscend === 'true' ? true : false
                    }

                    if (sourceItemBody.isEnabled) {
                      this.params.isEnabled = sourceItemBody.isEnabled === 'true' ? true : false
                    }

                  }


                  const res = await this._getDraftProductList({
                    ...this.params,
                  })

                  tool.route.pushParamsObjHistory(this.params, this.props.history)
                  return {
                    data: res.data.map(item => {
                      let finalPriceDataObj: DraftProductColumnData['finalPriceDataObj'] = {}
                      for (const finalPriceData of item.finalPriceDatas) {
                        const draftMachine = this.state.draftMachines.find(item => item.id === finalPriceData.machineId)
                        if (draftMachine) {
                          finalPriceDataObj[finalPriceData.machineId] = {
                            draftMachine,
                            finalPriceData,
                          }
                        }
                      }

                      this.setState({
                        finalPriceDataObj,
                      })
                      return ({
                        ...item,
                        draft,
                        finalPriceDataObj,
                      })
                    }),
                    totalCount: res.totalCount,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(DraftProductList)


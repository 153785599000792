import axios from 'axios'
import *as antd from "antd";

const http = axios.create({
    baseURL: process.env['REACT_APP_API_URL'] ? process.env['REACT_APP_API_URL'].replace(/https:/, window.location.protocol) : '/admin/api',
    // validateStatus:status=>status===200,
})
http.interceptors.request.use(config => {
    config.headers['Authorization'] = localStorage['token'] || ''
    return config
})
http.interceptors.response.use(response => {
    console.log(`${response.config.method}====>${response.config.url}`, response)
    return response
}, (error) => {


    console.log('【response】', error.response?.data.eror)
    const message: string | undefined = error.response?.data.message

    if (message) {
        antd.message.error(message)
    }
    const status = error.response?.status

    if (status === 401) {
        window.location.hash = '/login'
    }
    return Promise.reject(error)
})


export default http


import moment from "moment"
import { RouteChildrenProps } from "react-router-dom"
import { SourceItem } from "../components/JJ_Table"


/**
 * 推送到History

 */
export const pushParamsObjHistory = (paramsObj: { [x: string]: any | undefined }, history: RouteChildrenProps['history']) => {
    history.push(formatParamsObj(paramsObj))
}

/**
 * 對象轉路徑參數
 */
export const formatParamsObj = (paramsObj?: { [x: string]: any | undefined }) => {
    let paramsString = ''
    if (paramsObj) {
        paramsString = Object
            .keys(paramsObj)
            .filter(key => !(paramsObj[key] === undefined || paramsObj[key] === null || paramsObj[key] === ''))
            .map(key => {

                let value = paramsObj[key]

                if (typeof value === 'object') {
                    value = JSON.stringify(value)
                }
                return (`${[key]}=${value}`)
            })
            .join('&')

        paramsString = `?${paramsString}`
    }
    return paramsString
}


/**
 * 路徑Key
 */
export type PathKey =
    '/system/pricing-config' |
    '/product/list' |
    '/product/type-list' |
    '/product/brand-list' |
    '/machine/list' |
    '/draft/list' |
    '/draft/main/:draftId'

/**
 * 獲取路徑數據
 */
export const getPath = (key: PathKey, paramsObj?: { [x: string]: any | undefined }) => {

    let paramsString = formatParamsObj(paramsObj)


    switch (key) {

        case '/draft/list': {
            return {
                key: key + paramsString,
                title: '草稿清單',
                match: '^\/draft\/list[\/|a-z]*$',
            }
        }
        case '/product/list': {
            return {
                key: key + paramsString,
                title: '產品清單',
                match: '^\/product\/list[\/|a-z]*$',
            }
        }

        case '/product/type-list': {
            return {
                key: key + paramsString,
                title: '產品類別清單',
                match: '^\/product\/type-list[\/|a-z]*$',
            }
        }
        case '/product/brand-list': {
            return {
                key: key + paramsString,
                title: '產品品牌清單',
                match: '^\/product\/brand-list[\/|a-z]*$',
            }
        }
        case '/machine/list': {
            return {
                key: key + paramsString,
                title: '機器清單',
                match: '^\/machine\/list[\/|a-z]*$',
            }
        }
        case '/system/pricing-config': {
            return {
                key: key + paramsString,
                title: '定價配置文件',
                match: '^\/system\/pricing-config[\/|a-z]*$',
            }
        }

        case '/draft/main/:draftId': {
            return {
                key: key + paramsString,
                title: '草稿內頁',
                match: '^\/draft\/main[\/|a-z]*$',
            }
        }

    }

}


/**
 * 獲取路由搜索參數
 */
export const getSearchParams = (data: { search: string, sourceItems: SourceItem[] }) => {

    const params = new URLSearchParams(data.search);
    for (const item of data.sourceItems) {

        item.defaultValue = [undefined, null, '', 'undefined'].some(data => data === params.get(item.key))
            ? item.defaultValue
            : params.get(item.key)


        if (item.type === 'select' && item.mode === 'multiple') {

            let data = params.get(item.key) || undefined
            data = data ? JSON.parse(data) : data

            item.defaultValue = data || item.defaultValue

        }


        if (item.key === 'rangePicker') {
            item.defaultValue = params.get('startTime')
                ? [moment(params.get('startTime')), moment(params.get('endTime'))]
                : item.defaultValue
        }
    }

    return data.sourceItems
}


/**
 * 过滤无效值
 */
export const filterParams = (obj: any) => {
    let newObj = {};
    for (const key in obj) {
        let data = obj[key]

        if (!(data === null || data === undefined)) {
            newObj[key] = data;
        }
    }
    return newObj;
}



/**
 * 獲取查詢字符串
 */
export const getQueryString = (obj: any) => {
    const paramsObject = filterParams(obj)


    const params = new URLSearchParams();

    Object.keys(paramsObject).forEach(key => {



        if (Array.isArray(paramsObject[key])) {
            // 使用key后面跟 "[]" 来指定数组
            paramsObject[key].forEach(value => params.append(`${key}[]`, value));
        } else if (paramsObject[key]) {
            // 对于单个值，直接设置键值对
            params.set(key, paramsObject[key]);
        }  
        
    });

    const queryString = params.toString();
    return queryString;
}

import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { finalPriceData, GetMachineRes, GetProductTypeRes } from "../api"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import tool from "../tool"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"

export type ProductTypeColumnData = GetProductTypeRes & {}

export type ProductTypeColumnParams = {
  user: ActionUserRes
}


export const getProductTypeColumns = (params?: ProductTypeColumnParams): ColumnsType<ProductTypeColumnData> => {


  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },


    {
      title: 'Kiosk 產品類別ID',
      dataIndex: 'kioskProductTypeId',
      key: 'kioskProductTypeId',
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '名稱',
      dataIndex: ['name'],
      key: 'name',
      render: (value, record) => {
        return tool.local.formatMessage({ user: params?.user, data: record?.name })
      }
    },
    {
      title: '順序',
      dataIndex: ['order'],
      key: 'order',
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}

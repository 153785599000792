
import { ApiConfig, ApiConfigType } from "./api-config"
import * as Result from "./api-result"
import * as Parameters from './api-parameters-type'
import * as Res from './api-res-type'
import { AxiosInstance } from 'axios'
import { createHttp } from './http';


export class Api {

  config: ApiConfigType
  http: AxiosInstance

  constructor (config: ApiConfigType = ApiConfig) {
    this.config = config
    this.http = createHttp(this.config)
  }



  //  Auth 授权

  /**
   * 登录
   */
  async PostAuthPasswordLogin(params: Parameters.PostAuthPasswordLoginParameters): Promise<Result.PostAuthPasswordLoginResult> {
    try {
      const res = await this.http.post(`/admin/auth/password/login`, params)
      const data: Res.PostAuthPasswordLoginRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  /**
   * 修改密码
   */
  async PostAuthPasswordChange(params: Parameters.PostAuthPasswordChangeParameters): Promise<Result.PostAuthPasswordChangeResult> {
    try {
      const res = await this.http.post(`/admin/auth/password/change`, params)
      const data: Res.PostAuthPasswordChangeRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }


  /**
   * 退出当前登录
   */
  async DeleteAuth(): Promise<Result.DeleteAuthResult> {
    try {
      const res = await this.http.delete(`/admin/auth`)
      const data: Res.DeleteAuthRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  //  User 用户 

  /**
   * 查询单个用户
   */
  async GetUser(params: Parameters.GetUserParameters): Promise<Result.GetUserResult> {
    try {
      const res = await this.http.get(`/admin/user/${params.id}`, { params })
      const data: Res.GetUserRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }



  //  PricingConfig 定价配置文件
  async GetPricingConfig(): Promise<Result.GetPricingConfigResult> {
    try {
      const res = await this.http.get(`/admin/pricing-config`,)
      const data: Res.GetPricingConfigRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  // Product 产品 
  async GetProductList(params: Parameters.GetProductListParameters): Promise<Result.GetProductListResult> {
    try {
      const res = await this.http.get(`/admin/product`, { params })
      const data: Res.GetProductListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetProduct(params: Parameters.GetProductParameters): Promise<Result.GetProductResult> {
    try {
      const res = await this.http.get(`/admin/product/${params.id}`, { params })
      const data: Res.GetProductRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }




  async GetProductTypeList(params: Parameters.GetProductTypeListParameters): Promise<Result.GetProductTypeListResult> {
    try {
      const res = await this.http.get(`/admin/product-type`, { params })
      const data: Res.GetProductTypeListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetProductType(params: Parameters.GetProductTypeParameters): Promise<Result.GetProductTypeResult> {
    try {
      const res = await this.http.get(`/admin/product-type/${params.id}`, { params })
      const data: Res.GetProductTypeRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }


  async GetProductBrandList(params: Parameters.GetProductBrandListParameters): Promise<Result.GetProductBrandListResult> {
    try {
      const res = await this.http.get(`/admin/product-brand`, { params })
      const data: Res.GetProductBrandListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetProductBrand(params: Parameters.GetProductBrandParameters): Promise<Result.GetProductBrandResult> {
    try {
      const res = await this.http.get(`/admin/product-brand/${params.id}`, { params })
      const data: Res.GetProductBrandRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }



  // Machine 机器
  async GetMachineList(params: Parameters.GetMachineListParameters): Promise<Result.GetMachineListResult> {
    try {
      const res = await this.http.get(`/admin/machine`, { params })
      const data: Res.GetMachineListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetMachine(params: Parameters.GetMachineParameters): Promise<Result.GetMachineResult> {
    try {
      const res = await this.http.get(`/admin/machine/${params.id}`, { params })
      const data: Res.GetMachineRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  // Draft 草稿
  async GetDraftList(params: Parameters.GetDraftListParameters): Promise<Result.GetDraftListResult> {
    try {
      const res = await this.http.get(`/admin/draft`, { params })
      const data: Res.GetDraftListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetDraft(params: Parameters.GetDraftParameters): Promise<Result.GetDraftResult> {
    try {
      const res = await this.http.get(`/admin/draft/${params.id}`, { params })
      const data: Res.GetDraftRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }


  async PostDraft(params: Parameters.PostDraftParameters): Promise<Result.PostDraftResult> {
    try {
      const res = await this.http.post(`/admin/draft`, params)
      const data: Res.PostDraftRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async PostDraftCompleted(params: Parameters.PostDraftCompletedParameters): Promise<Result.PostDraftCompletedResult> {
    try {
      const res = await this.http.post(`/admin/draft/completed`, params)
      const data: Res.PostDraftCompletedRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async PostDraftCancel(params: Parameters.PostDraftCancelParameters): Promise<Result.PostDraftCancelResult> {
    try {
      const res = await this.http.post(`/admin/draft/cancel`, params)
      const data: Res.PostDraftCancelRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async PostDraftBegin(params: Parameters.PostDraftBeginParameters): Promise<Result.PostDraftBeginResult> {
    try {
      const res = await this.http.post(`/admin/draft/begin`, params)
      const data: Res.PostDraftBeginRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async PutDraft(params: Parameters.PutDraftParameters): Promise<Result.PutDraftResult> {
    try {
      const res = await this.http.put(`/admin/draft/${params.id}`, params)
      const data: Res.PutDraftRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async DeleteDraft(params: Parameters.DeleteDraftParameters): Promise<Result.DeleteDraftResult> {
    try {
      const res = await this.http.delete(`/admin/draft/${params.id}`, { params })
      const data: Res.DeleteDraftRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  // DraftRecord 草稿记录
  async GetDraftRecordList(params: Parameters.GetDraftRecordListParameters): Promise<Result.GetDraftRecordListResult> {
    try {
      const res = await this.http.get(`/admin/draft-record`, { params })
      const data: Res.GetDraftRecordListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetDraftRecord(params: Parameters.GetDraftRecordParameters): Promise<Result.GetDraftRecordResult> {
    try {
      const res = await this.http.get(`/admin/draft-record/${params.id}`, { params })
      const data: Res.GetDraftRecordRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }
  // DraftPricingConfig 定价配置文件(草稿)

  async GetDraftPricingConfig(params: Parameters.GetDraftPricingConfigParameters): Promise<Result.GetDraftPricingConfigResult> {
    try {
      const res = await this.http.get(`/admin/draft-pricing-config`, { params })
      const data: Res.GetDraftPricingConfigRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async PutDraftPricingConfig(params: Parameters.PutDraftPricingConfigParameters): Promise<Result.PutDraftPricingConfigResult> {
    try {
      const res = await this.http.put(`/admin/draft-pricing-config/${params.id}`, params)
      const data: Res.PutDraftPricingConfigRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  // DraftProduct 产品（草稿）

  async GetDraftProductList(params: Parameters.GetDraftProductListParameters): Promise<Result.GetDraftProductListResult> {
    try {
      const res = await this.http.get(`/admin/draft-product`, { params })
      const data: Res.GetDraftProductListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetDraftProduct(params: Parameters.GetDraftProductParameters): Promise<Result.GetDraftProductResult> {
    try {
      const res = await this.http.get(`/admin/draft-product/${params.id}`, { params })
      const data: Res.GetDraftProductRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }


  async PostDraftProduct(params: Parameters.PostDraftProductParameters): Promise<Result.PostDraftProductResult> {
    try {
      const res = await this.http.post(`/admin/draft-product`, params)
      const data: Res.PostDraftProductRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }


  async PutDraftProduct(params: Parameters.PutDraftProductParameters): Promise<Result.PutDraftProductResult> {
    try {
      const res = await this.http.put(`/admin/draft-product/${params.id}`, params)
      const data: Res.PutDraftProductRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async DeleteDraftProduct(params: Parameters.DeleteDraftProductParameters): Promise<Result.DeleteDraftProductResult> {
    try {
      const res = await this.http.delete(`/admin/draft-product/${params.id}`, { params })
      const data: Res.DeleteDraftProductRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  // DraftMachine 机器（草稿） 

  async GetDraftMachineList(params: Parameters.GetDraftMachineListParameters): Promise<Result.GetDraftMachineListResult> {
    try {
      const res = await this.http.get(`/admin/draft-machine`, { params })
      const data: Res.GetDraftMachineListRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetDraftMachine(params: Parameters.GetDraftMachineParameters): Promise<Result.GetDraftMachineResult> {
    try {
      const res = await this.http.get(`/admin/draft-machine/${params.id}`, { params })
      const data: Res.GetDraftMachineRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }


  async PostDraftMachine(params: Parameters.PostDraftMachineParameters): Promise<Result.PostDraftMachineResult> {
    try {
      const res = await this.http.post(`/admin/draft-machine`, params)
      const data: Res.PostDraftMachineRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }


  async PutDraftMachine(params: Parameters.PutDraftMachineParameters): Promise<Result.PutDraftMachineResult> {
    try {
      const res = await this.http.put(`/admin/draft-machine/${params.id}`, params)
      const data: Res.PutDraftMachineRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async DeleteDraftMachine(params: Parameters.DeleteDraftMachineParameters): Promise<Result.DeleteDraftMachineResult> {
    try {
      const res = await this.http.delete(`/admin/draft-machine/${params.id}`, { params })
      const data: Res.DeleteDraftMachineRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }


  async GetOtherProduct(params: Parameters.GetOtherProductParameters): Promise<Result.GetOtherProductResult> {
    try {
      const res = await this.http.get(`/admin/other/product`, { params })
      const data: Res.GetOtherProductRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }

  async GetOtherMachine(params: Parameters.GetOtherMachineParameters): Promise<Result.GetOtherMachineResult> {
    try {
      const res = await this.http.get(`/admin/other/machine`, { params })
      const data: Res.GetOtherMachineRes = res.data
      return { kind: 'ok', data }
    } catch (e: any) {
      const response = e?.response
      return { kind: response?.data?.errorCode || '' }
    }
  }



}



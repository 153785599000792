
import *as store from './ToolStore'
import http from './http'
import *as  route from './route'
import *as  color from './color'
import *as  local from './local'
import *as  number from './number'


export default {
    store,
    http,
    route,
    color,
    local,
    number,
}


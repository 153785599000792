import React, { useState } from 'react';
import { Button, Descriptions, Modal } from 'antd';



const getDescriptions = () => {


  const list: DescriptionData[] = [
    {
      label: '附加費用',
      value: '（停車加油+其他+場地通行費定價+場地距離定價）/機器大小定價 ×  5',
      span: 3,
    },
    {
      span: 3,
      label: '倍數',
      value: `(機器.佣金 < 配置定價文件.佣金 ? 配置定價文件.佣金 : 默認零售價加成:默認零售價加成) / (1 - (機器.佣金 + 0.02)) `,
    },
    {
      span: 3,
      label: '產品最終定價',
      value: (<>
        <div>roundDecimal(roundToHalf(零售价 × 倍數 + 附加費用)* 最終價格乘數)</div>
        <div style={{ marginTop: 10, color: 'rgba(0,0,0,0.5)', fontSize: 10 }}>roundToHalf(x)表示將x向上四捨五入取最接近的0.5倍數</div>
        <div style={{ color: 'rgba(0,0,0,0.5)', fontSize: 10 }}>roundDecimal(x) 表示將x四捨五入到第一位小數</div>
      </>),
    },
  ]
  return setDescriptions({
    list: list,
  })

}


type DescriptionData = {
  label: string
  value: any
  span?: number
}


const setDescriptions = (data: { list: DescriptionData[] }) => {
  return (
    <Descriptions
      size='middle' layout="vertical" bordered>
      {data.list.map((item, index) => {
        return (
          <Descriptions.Item
            label={item.label}
            span={item.span}
            key={index}
          >
            {item.value}
          </Descriptions.Item>
        )
      })}
    </Descriptions>
  )
}

export const ProductUnitPriceInfoModal = () => {



  const [visible, setVisible] = useState(false)
  const title = '定價公式說明'
  return (
    <>
      <Button
        onClick={() => setVisible(true)}
      >
        {title}
      </Button>

      <Modal
        maskClosable={false}
        title={title}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={'85%'}
      >
        {getDescriptions()}

      </Modal>
    </>
  )
}


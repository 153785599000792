import { AllErrorCode } from './api-error-code-type'

export const getApiErrorCodeText = (errorCode: AllErrorCode) => {
    switch (errorCode) {

        case 'INVALID_PARAMS': {
            return `無效參數 (${errorCode})`
        }
        case 'INVALID_TOKEN': {
            return `請重新登錄 (${errorCode})`
        }
        case 'INVALID_PERMISSION': {
            return `權限不足 (${errorCode})`
        }
        case 'INCORRECT_PASSWORD': {
            return `密碼不正確 (${errorCode})`
        }
        case 'INVALID_USER': {
            return `用戶不存在 (${errorCode})`
        }
        case 'NOT_FOUND': {
            return `該數據已刪除、不存在 (${errorCode})`
        }

        case 'NOT_DRAFT_ID': {
            return `無效草稿ID (${errorCode})`
        }

        case 'DUPLICATE_CODE':
        case 'DUPLICATE_SHOP_NO': {
            return `重複唯一碼 (${errorCode})`
        }

        case 'INVALID_CODE':
        case 'INVALID_SHOP_NO': {
            return `無效唯一碼 (${errorCode})`
        }

        case 'KIOSK_SERVER_ERROR': {
            return `kiosk cloud 系統錯誤，請聯繫開發管理人員(${errorCode})`
        }

        default: {
            return `系統錯誤，請聯繫開發管理人員 (${errorCode})`
        }
    }
}
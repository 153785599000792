import { Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetMachineRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import tool from "../tool"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"



export type MachineColumnData = GetMachineRes & {

}
type MachineColumnParamOnActionKey = 'PDF'


export type MachineColumnParams = {
  user: ActionUserRes
  onAction?(key: MachineColumnParamOnActionKey, record: MachineColumnData): void
}

export const getMachineColumns = (params?: MachineColumnParams): ColumnsType<MachineColumnData> => {

  return [

    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: MachineColumnParamOnActionKey, name: string, disabled?: boolean, link?: string }[] = [
          { key: 'PDF', name: '導出PDF' },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: item.disabled,
                key: item.key,
                name: item.name,
                link: item.link,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },

    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '名稱',
      dataIndex: ['name'],
      key: 'name',
      width: 200,
    },

    {
      title: '唯一碼',
      dataIndex: ['shopNo'],
      key: 'shopNo',
      width: 200,
    },
    {
      title: <div style={{ color: tool.color.primary_color }}>區域</div>,
      dataIndex: ['region'],
      key: 'region',
      width: 80,
    },

    {
      title: <div style={{ color: tool.color.primary_color }}>公司</div>,
      dataIndex: ['operator'],
      key: 'operator',
      width: 200,
    },

    {
      title: <div style={{ color: tool.color.primary_color }}>佣金/手續費</div>,
      dataIndex: ['commissions'],
      key: 'commissions',
      width: 100,
      render: (value, record) => {
        value = value || 0
        return `${Number(value) * 100}%`
      },
    },

    {
      title: <div style={{ color: tool.color.primary_color }}>大小</div>,
      dataIndex: ['size'],
      key: 'size',
      width: 80,
    },

    {
      title: <div style={{ color: tool.color.primary_color }}>其他</div>,
      dataIndex: ['other'],
      key: 'other',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 2),
    },

    {
      title: '是否啟用',
      dataIndex: ['isEnabled'],
      key: 'isEnabled',
      width: 100,
      render: (value, record) => {
        return (
          record.isEnabled
            ? <Tag color="blue">啟用</Tag>
            : <Tag color="red">停用</Tag>
        )
      }
    },
    {
      title: <div style={{ color: tool.color.primary_color }}>場地收費</div>,
      dataIndex: ['tollPricing'],
      key: 'tollPricing',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 1),
    },
    {
      title: <div style={{ color: tool.color.primary_color }}>停車/加油</div>,
      dataIndex: ['parkingRefill'],
      key: 'parkingRefill',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 1),
    },
    {
      title: '距離定價',
      dataIndex: ['distance'],
      key: 'distance',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 1),
    },

    {
      title: '附加費用',
      dataIndex: ['additional'],
      key: 'additional',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 2),
    },

    {
      title: '倍數',
      dataIndex: ['factor'],
      key: 'factor',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 4),
    },
    {
      title: <div style={{ color: tool.color.primary_color }}>最終價格乘數</div>,
      dataIndex: ['priceMultiplier'],
      key: 'priceMultiplier',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 2),
    },

    ...getCreatedAtAndUpdataAtColumn(),
  ]
}

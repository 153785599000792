import React, { ReactElement } from 'react';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';



interface PageProps {
  value?: boolean
  onChange?: (value?: boolean | {}) => void;
  switchProps?: SwitchProps
}

interface PageState {

}

interface Page {

}

export default class JJ_FromSwitch extends React.Component<PageProps, PageState> implements Page {

  componentDidMount() {
  }

  constructor (props: any) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <Switch
        {...this.props.switchProps}
        onChange={this.props.onChange}
        checked={this.props.value}
      />
    )
  }

}


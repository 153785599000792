import { Descriptions, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetDraftRecordRes, GetDraftRes, GetMachineRes, GetPricingConfigRes, GetProductRes, GetUserRes, I18nObj } from "../api"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"


type DescriptionData = {
  label: string
  value: any
  span?: number
  focus?: boolean
}



const getDescriptionsI18nValue = (value?: I18nObj) => {
  return (
    <div>
      繁体: {value ? value['zh-tw'] || '--' : '--'}
      <br />
      简体: {value ? value['zh-cn'] || '--' : '--'}
      <br />
      English: {value ? value['en'] || '--' : '--'}
    </div>
  )
}



const setDescriptions = (data: { list: DescriptionData[], title: string }) => {
  return (
    <Descriptions
      size='small' layout="vertical" title={data.title} bordered>
      {data.list.map((item, index) => {
        return (
          <Descriptions.Item
            className={item.focus ? 'draftRecordDescriptionsItemFocus' : undefined}
            label={item.label}
            span={item.span}
            key={index}
          >
            {item.value}
          </Descriptions.Item>
        )
      })}
    </Descriptions>
  )
}


const dataChangeValue = (data: Object | undefined) => {
  if (!data) return ''

  return (
    <>
      {
        Object.keys(data).map((key, index) => {
          return (
            <div
              key={index}
            >
              {`${key}: ${data[key]}`}
              {index !== Object.keys(data).length && (
                <br />
              )}
            </div>
          )
        })
      }
    </>
  )

}

const getProductDescriptions = (product: GetProductRes | undefined, productCompare?: GetProductRes | undefined) => {
  if (product) {

    const list: DescriptionData[] = [
      {
        label: '唯一碼',
        value: product.code,
        focus: productCompare && product.code !== productCompare?.code
      },
      {
        label: '名稱',
        value: getDescriptionsI18nValue(product.name),
        focus: productCompare && getDescriptionsI18nValue(product.name) !== getDescriptionsI18nValue(productCompare.name)
      },
      {
        label: '成本',
        value: product.costPrice,
        focus: productCompare && product.costPrice !== productCompare?.costPrice
      },
      {
        label: '零售價',
        value: product.retailPrice,
        focus: productCompare && product.retailPrice !== productCompare?.retailPrice
      },
    ]
    return setDescriptions({
      list: list,
      title: '產品'
    })

  }

  return ''

}


const getMachineDescriptions = (machine: GetMachineRes | undefined, machineCompare?: GetMachineRes | undefined) => {
  if (machine) {
    const list: DescriptionData[] = [
      { label: '名稱', value: machine.name, focus: machineCompare && machine.name !== machineCompare?.name },
      { label: '唯一碼', value: machine.shopNo, focus: machineCompare && machine.shopNo !== machineCompare?.shopNo },
      { label: '區域', value: machine.region, focus: machineCompare && machine.region !== machineCompare?.region },
      { label: '公司', value: machine.operator, focus: machineCompare && machine.operator !== machineCompare?.operator },
      { label: '佣金/手續費', value: machine.commissions, focus: machineCompare && machine.commissions !== machineCompare?.commissions },
      { label: '大小', value: machine.size, focus: machineCompare && machine.size !== machineCompare?.size },
      { label: '其他', value: machine.other, focus: machineCompare && machine.other !== machineCompare?.other },
      {
        label: '是否啟用', value: machine.isEnabled
          ? <Tag color="blue">啟用</Tag>
          : <Tag color="red">停用</Tag>,
        focus: machineCompare && machine.isEnabled !== machineCompare?.isEnabled,
      },
      { label: '場地收費', value: machine.tollPricing || 0, focus: machineCompare && machine.tollPricing !== machineCompare?.tollPricing },
      { label: '距離定價', value: machine.distance || 0, focus: machineCompare && machine.distance !== machineCompare?.distance },
      { label: '附加費用', value: machine.additional || 0, focus: machineCompare && machine.additional !== machineCompare?.additional },
      { label: '倍數', value: machine.factor || 0, focus: machineCompare && machine.factor !== machineCompare?.factor },
      { label: '停車/加油', value: machine.parkingRefill, focus: machineCompare && machine.parkingRefill !== machineCompare?.parkingRefill },
      { label: '最終價格乘數', value: machine.priceMultiplier, focus: machineCompare && machine.priceMultiplier !== machineCompare?.priceMultiplier },
    ]
    return setDescriptions({
      list: list,
      title: '機器'
    })
  }
  return ''
}



const getPricingConfigDescriptions = (pricingConfig: GetPricingConfigRes | undefined, pricingConfigCompare?: GetPricingConfigRes | undefined) => {
  if (pricingConfig) {
    const list: DescriptionData[] = [
      {
        label: '機器大小定價',
        focus: pricingConfigCompare && JSON.stringify(pricingConfig.machineSizePricing) !== JSON.stringify(pricingConfigCompare.machineSizePricing),
        value: dataChangeValue(pricingConfig.machineSizePricing)
      },
      {
        label: '場地距離定價',
        focus: pricingConfigCompare && JSON.stringify(pricingConfig.distancePricing) !== JSON.stringify(pricingConfigCompare.distancePricing),
        value: dataChangeValue(pricingConfig.distancePricing)
      },
      {
        label: '場地通行費定價',
        focus: pricingConfigCompare && JSON.stringify(pricingConfig.tollPricing) !== JSON.stringify(pricingConfigCompare.tollPricing),
        value: dataChangeValue(pricingConfig.tollPricing)
      },
      {
        label: '佣金',
        focus: pricingConfigCompare && pricingConfig.freeCommissionsThreshold !== pricingConfigCompare?.freeCommissionsThreshold,
        value: pricingConfig.freeCommissionsThreshold
      },
      {
        label: '默認零售價加成',
        focus: pricingConfigCompare && pricingConfig.defaultRetailPriceMarkup !== pricingConfigCompare?.defaultRetailPriceMarkup,
        value: pricingConfig.defaultRetailPriceMarkup
      },
    ]

    return setDescriptions({
      list: list,
      title: '定價配置文件'
    })
  }
  return ''
}


export const getEditStatusInfo = (status: GetDraftRecordRes['editStatus']) => {
  switch (status) {
    case 'Delete': {
      const name = '刪除'
      return {
        value: status,
        name,
        tag: (
          <Tag color='red'>
            {name}
          </Tag>
        )
      }
    }
    case 'Post': {
      const name = '添加'
      return {
        value: status,
        name,
        tag: (
          <Tag color='success'>
            {name}
          </Tag>
        )
      }
    }
    case 'Put': {
      const name = '修改'
      return {
        value: status,
        name,
        tag: (
          <Tag color='green'>
            {name}
          </Tag>
        )
      }
    }
  }

}


export const getCorrelationTypeInfo = (status: GetDraftRecordRes['correlationType']) => {
  switch (status) {
    case 'Machine': {
      const name = '機器'
      return {
        value: status,
        name,
        tag: (
          <Tag color='red'>
            {name}
          </Tag>
        )
      }
    }
    case 'PricingConfig': {
      const name = '定價配置'
      return {
        value: status,
        name,
        tag: (
          <Tag color='success'>
            {name}
          </Tag>
        )
      }
    }
    case 'Product': {
      const name = '產品'
      return {
        value: status,
        name,
        tag: (
          <Tag color='green'>
            {name}
          </Tag>
        )
      }
    }
  }

}

export type DraftRecordColumnData = GetDraftRecordRes & {
  draft?: GetDraftRes
  createUser?: GetUserRes
}

type DraftRecordColumnParams = {
  user: ActionUserRes

}

export const getDraftRecordColumns = (params?: DraftRecordColumnParams): ColumnsType<DraftRecordColumnData> => {

  return [


    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },


    {
      title: '草稿名稱',
      dataIndex: ['draft', 'name'],
      key: 'name',
      width: 150,
    },



    {
      title: '舊數據',
      dataIndex: 'oldData',
      key: 'oldData',
      width: 500,
      render: (value, record) => {

        switch (record.correlationType) {
          case 'Machine': {
            return getMachineDescriptions(record.oldData, record.newData)
          }
          case 'PricingConfig': {
            return getPricingConfigDescriptions(record.oldData, record.newData)
          }
          case 'Product': {
            return getProductDescriptions(record.oldData, record.newData)
          }
        }

      },
    },
    {
      title: '新數據',
      dataIndex: 'newData',
      key: 'newData',
      width: 500,
      render: (value, record) => {

        switch (record.correlationType) {
          case 'Machine': {
            return getMachineDescriptions(record.newData, record.oldData)
          }
          case 'PricingConfig': {
            return getPricingConfigDescriptions(record.newData, record.oldData)
          }
          case 'Product': {
            return getProductDescriptions(record.newData, record.oldData)
          }
        }

      },
    },



    {
      title: '創建用戶',
      dataIndex: 'createUser',
      key: 'createUser',
      width: 100,
      render: (value, record) => record.createUser?.nickname
    },

    {
      title: '编辑状态',
      dataIndex: 'editStatus',
      key: 'editStatus',
      width: 100,
      render: (value, record) => getEditStatusInfo(record.editStatus).tag,
    },

    {
      title: '關聯類別',
      dataIndex: 'correlationType',
      key: 'correlationType',
      width: 100,
      render: (value, record) => getCorrelationTypeInfo(record.correlationType).tag,
    },


    ...getCreatedAtAndUpdataAtColumn(),
  ]
}

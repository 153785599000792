
import config from '../config';

const API_URL = config.REACT_APP_API_URL ? config.REACT_APP_API_URL.replace(/^https:/, window.location.protocol) : '/admin/api'


console.log('API_URL', API_URL)

export interface ApiConfigType {
  url: string
  timeout: number
}

export const ApiConfig: ApiConfigType = {
  url: API_URL,
  timeout: 10000,
}

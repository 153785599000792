import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Space } from 'antd';
import api, { DeleteDraftMachineParameters, GetUserParameters, GetDraftMachineRes, GetDraftParameters, GetDraftRes, GetDraftMachineListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import DraftMachineEditAndAdd, { DraftMachineEditAndAddPropsType } from './DraftMachineAdd';
import { connect, ConnectedProps } from 'react-redux';
import { getDraftMachineColumns, DraftMachineColumnData } from '../../table-columns/DraftMachine';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import { PlusOutlined } from '@ant-design/icons';
import { ModalOther, ModalOtherProps } from '../other/ModalOther';
import MachineExportPdfModal from '../machine/MachineExportPdfModal';

const { confirm } = Modal;

type EditAndAddModalType = DraftMachineEditAndAddPropsType

interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key?: string
  } & EditAndAddModalType
  draft?: GetDraftRes,

  modalOther?: {
    key?: any
  } & ModalOtherProps,



  machineExportPdfModalProps?: {
    draftId?: string
    key?: any
    machineId?: string
    title?: string
    visible?: boolean
    onCancel?(): void
    onFinish?(url: string): void
  }

}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetDraftMachineRes> | null
}


type PageProps = Props & RouteChildrenProps<{ draftId: string }> & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DraftMachineList extends React.Component<PageProps, PageState> implements Page {


  params: {
    [x: string]: any
  } = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
      editAndAddModal: {
        id: '',
        type: 'edit',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<DraftMachineColumnData> | null
  componentDidMount() {
    this._initData()
  }


  _getDraft = async (params: GetDraftParameters) => {
    const res = await api.GetDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDraftMachineList = async (params: GetDraftMachineListParameters) => {
    const res = await api.GetDraftMachineList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteDraftMachine = async (params: DeleteDraftMachineParameters) => {
    const res = await api.DeleteDraftMachine(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _isDisable = () => {
    return !!(this.state.draft?.approveStatus !== 'BEGIN')
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })

      const draftId = this.props.match?.params.draftId
      const draft = await this._getDraft({ id: draftId || '' })


      this.setState({
        draft,
        isSpinLoading: false,
        initLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="DraftMachineList"
      >

        {this.state.machineExportPdfModalProps?.key && this.state.machineExportPdfModalProps?.machineId && (
          <MachineExportPdfModal
            {...this.state.machineExportPdfModalProps}
            isDraft={true}
            user={this.props.user}
            key={this.state.machineExportPdfModalProps.key}
            machineId={this.state.machineExportPdfModalProps.machineId}
            visible={this.state.machineExportPdfModalProps.visible}
            title={'導出PDF'}
            onCancel={() => {
              this.setState({
                machineExportPdfModalProps: {
                  ...this.state.machineExportPdfModalProps || {},
                  visible: false,
                }
              })
            }}
            onFinish={(url) => {
              console.log('url', url)
              window.open(url)
              this.setState({
                machineExportPdfModalProps: {
                  ...this.state.machineExportPdfModalProps || {},
                  // visible: false,
                }
              })
            }}
          />
        )}



        {this.state.modalOther && (
          <ModalOther
            {...this.state.modalOther}
          />
        )}

        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯機器（草稿）' : '新建機器（草稿）'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          {this.state.editAndAddModal.key && (
            <DraftMachineEditAndAdd
              {...this.state.editAndAddModal}
              onFinish={async (props) => {
                this.setState(state => ({
                  editAndAddModal: { ...state.editAndAddModal, show: false }
                }))
                switch (props.type) {
                  case 'add': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`添加成功`)
                  }
                    break
                  case 'edit': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`編輯成功`)
                  }
                }

              }}
            />
          )}

        </Modal>


        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<DraftMachineColumnData, {
                isAscend?: 'true' | 'false'
                isEnabled?: 'true' | 'false'

              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'false',
                        key: 'isAscend',
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },
                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isEnabled',
                        placeholder: '選擇是否啟用',
                        allowClear: true,
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '啟用',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '停用',
                            disabled: false,
                          },
                        ]
                      },
                    ]
                  })

                }


                columns={getDraftMachineColumns({
                  user: this.props.user,
                  onAction: (key, record) => {
                    const name = record.name
                    switch (key) {
                      case 'delete': {
                        confirm({
                          title: `是否刪除(${name})`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: async () => {
                            try {
                              await this._deleteDraftMachine({ id: record.id })
                              this.tableRef && this.tableRef.refreshData()
                              message.success(`${name} 删除成功`)
                            } catch (error: any) {
                              message.error(`${name} 删除失败 [${error?.message}]`);
                            }
                          },
                          onCancel() {
                          },
                        });
                      }
                        break
                      case 'edit': {
                        this.setState(state => ({
                          editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                        }))
                      }
                        break

                      case 'PDF': {
                        this.setState({
                          machineExportPdfModalProps: {
                            draftId: record.draftId,
                            visible: true,
                            key: Date.now(),
                            machineId: record.id,
                          }
                        })
                      }
                        break
                    }
                  }
                })}
                tableProps={{
                  scroll: {
                    x: getDraftMachineColumns().reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  },
                  onRow: (record) => {

                    return {
                      style: {
                        backgroundColor: record.size ? undefined : 'rgba(230,135,135,0.1)',
                      }
                    }
                  }
                }}
                title={'機器（草稿）列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody } = body
                  this.params = {}

                  if (sourceItemBody) {

                    if (sourceItemBody.isAscend) {
                      this.params.isAscend = sourceItemBody.isAscend === 'true' ? true : false
                    }
                    if (sourceItemBody.isEnabled) {
                      this.params.isEnabled = sourceItemBody.isEnabled === 'true' ? true : false
                    }
                  }

                  const draft = this.state.draft

                  const res = await this._getDraftMachineList({
                    ...body,
                    ...this.params,
                    draftId: draft?.id || '',
                  })

                  tool.route.pushParamsObjHistory(this.params, this.props.history)
                  return {
                    data: res.data.map(item => {
                      return ({
                        ...item,
                        draft,
                      })
                    }),
                    totalCount: res.totalCount,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(DraftMachineList)


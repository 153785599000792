import React, { } from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { getPath } from '../../tool/route';
import DraftList from '../draft/DraftList';
import DraftMain from '../draftMain/DraftMain';
import ProductList from '../product/ProductList';
import MachineList from '../machine/MachineList';
import PricingConfig from '../pricingConfig/PricingConfig';
import ProductBrandList from '../productBrand/ProductBrandList';
import ProductTypeList from '../productType/ProductTypeList';



interface PageState {


}


interface PageProps {

}

const { Content } = Layout;

export default class MainContent extends React.Component<PageProps, PageState>  {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {

    };
  }

  render() {

    return (
      <Content
        className="site-layout-background"
        style={{
          margin: '24px 16px',
          padding: 24,
          overflow: 'auto',
        }}
      >
        <Switch>
          <Route path={getPath('/draft/list').key} exact component={DraftList}></Route>
          <Route path={getPath('/draft/main/:draftId').key} component={DraftMain}></Route>

          <Route path={getPath('/product/list').key} exact component={ProductList}></Route>
          <Route path={getPath('/product/type-list').key} exact component={ProductTypeList}></Route>
          <Route path={getPath('/product/brand-list').key} exact component={ProductBrandList}></Route>
          <Route path={getPath('/machine/list').key} exact component={MachineList}></Route>
          <Route path={getPath('/system/pricing-config').key} exact component={PricingConfig}></Route>

          <Route component={DraftList} />
        </Switch>
      </Content>
    )
  }

}

[].reduce((previousValue, currentValue, currentIndex, array: any[]) => {
  return {
    ...previousValue,
  }
}, {
  time: '',
  count: 0,
})
import ActionType from './ActionType'
import { MyThunkDispatch } from './action-interface'
import api, { GetUserRes } from '../api'




const upDataAction = (data: ActionUserRes) => {
    return {
        type: ActionType.userType.GET_DATA,
        ...data
    }
}



const initDataAction = () => {
    return {
        type: ActionType.userType.INIT_DATA,
    }
}



export interface ActionUserRes {
    data: GetUserRes
}

/**
 * 获取用户数据
 */
export const getUser = () => {

    return async (dispatch: MyThunkDispatch) => {
        const res = await api.GetUser({ id: 'me' })
        if (res.kind === 'ok') {
            const data: ActionUserRes = { data: res.data }
            dispatch(upDataAction(data))
        }
        return res
    }
}



/**
 * 初始化
 */
export const initData = () => {

    return (dispatch: MyThunkDispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(initDataAction())
            resolve({})
        })
    }
}






import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './less/index.less'
import Root from './router/Root'
import { Provider } from 'react-redux'
import {store} from './store/configureStore';
import tool from './tool';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import zh_TW from 'antd/lib/locale/zh_TW';
tool.store.getStore(store)
moment.locale('zh-cn');



const render = (Component: any) => { // 增加react-hot-loader保持状态刷新操作，如果不需要可去掉并把下面注释的打开
  ReactDOM.render(


    <ConfigProvider
      locale={zh_TW}
    >
      <Provider store={store}>
        <Component store={store} />
      </Provider>
    </ConfigProvider>


    ,
    document.getElementById('root'),
  )
}

render(Root)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.info = () => { }
  console.warn = () => { }
}

import React from 'react';
import { Tabs, Card, Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import { TabsProps } from 'antd/lib/tabs';


export interface JJ_FromI18nInputTextProps {
  value?: any
  onChange?: (value?: any) => void;
  tabDatas: { title: string, key: string }[],
  disabled?: boolean
  inputProps?: InputProps
  tabsProps?: TabsProps
}

interface PageState {

}

interface Page {

}

export default class JJ_FromI18nInputText extends React.Component<JJ_FromI18nInputTextProps, PageState> implements Page {

  componentDidMount() {
  }

  constructor (props: any) {
    super(props);
    this.state = {}
  }

  render() {

    return (
      <Card>
        <Tabs
          defaultActiveKey="1"
          type='card'
          {...this.props.tabsProps}
        >
          {this.props.tabDatas.map(item => {
            const value: any = this.props.value || {}

            return (
              <Tabs.TabPane
                tab={item.title}
                key={item.key}
              >
                <Input
                  disabled={this.props.disabled}
                  defaultValue={value[item.key]}
                  {...this.props.inputProps}
                  onChange={v => {

                    const exchangeValue = {}

                    for (const data of this.props.tabDatas) {
                      exchangeValue[data.key] = ''
                    }



                    const newValue: any = this.props.value || {}
                    newValue[item.key] = v.target.value
                    this.props.onChange && this.props.onChange({
                      ...exchangeValue,
                      ...(newValue || {}),
                    })

                  }}
                />
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </Card>
    )
  }

}



import React, { ReactElement } from 'react'; import { Input, Spin, Table, Select, Row, Col, Radio, Switch, DatePicker } from 'antd';
import { ColumnsType, TableProps, TablePaginationConfig } from 'antd/lib/table/Table';
import { SelectProps } from 'antd/lib/select';
import { SwitchSize } from 'antd/lib/switch';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { DatePickerProps } from 'antd/lib/date-picker';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { InputProps } from 'antd/lib/input';
import JJ_TableHeader from './JJ_TableHeader';


interface DataSourceBody<SourceItemBody> {
  from?: number
  count?: number
  searchText?: string
  sourceItemBody?: SourceItemBody
}



type SourceItemSelect = {
  type: 'select'
  defaultValue?: string | number
  allowClear?: boolean
  key: string
  placeholder?: string
  options: {
    value: string | number
    name: string | JSX.Element
    disabled?: boolean
  }[]
} & SelectProps<any>
interface SwitchItemSelect {
  type: 'switch'
  defaultValue?: boolean
  disabled?: boolean
  unCheckedChildren?: React.ReactNode
  checkedChildren?: React.ReactNode
  size?: SwitchSize
  key: string
}

interface RangePickerItemSelect {
  type: 'rangePicker'
  key: string
  props: RangePickerProps<moment.Moment>
  defaultValue?: any
}


interface InputItem {
  type: 'input'
  key: string
  props: InputProps
  defaultValue?: any
}


interface DatePickerItemSelect {
  type: 'datePicker'
  key: string
  props: DatePickerProps
  defaultValue?: moment.Moment
}



/**
 * 单选组合
 */
interface RadioGroupItemSelect {
  type: 'radioGroup'
  defaultValue?: string
  key: string
  options: {
    value?: string
    name: string
    disabled?: boolean
  }[]
}



export type SourceItem = (SourceItemSelect | RadioGroupItemSelect | SwitchItemSelect | RangePickerItemSelect | DatePickerItemSelect | InputItem) & { span?: number }



interface PageProps<RecordType, SourceItemBody> {
  tableProps?: TableProps<RecordType>
  columns: ColumnsType<RecordType>
  title?: string
  expandable?: ExpandableConfig<RecordType>

  onDataSource(body: DataSourceBody<SourceItemBody>): Promise<{
    data: RecordType[]
    totalCount: number
  }>
  sourceItems?: (SourceItem)[]
  isSearchText?: boolean
  tableKey?: string
  /**
   * 默認分頁數量
   */
  count?: number
  style?: React.CSSProperties

}



interface PageState<RecordType, SourceItemBody> {
  isSpinLoading?: boolean
  isTableLoading?: boolean
  dataSource: {
    data: (RecordType)[]
    totalCount: number
  }
  pagination?: false | TablePaginationConfig | undefined
  sourceItems?: SourceItem[]
}


interface Page<RecordType, SourceItemBody> {
  _initData(): Promise<void>
  _getData(): Promise<void>
  refreshData(): Promise<void>
  dataSourceBody: DataSourceBody<SourceItemBody>
}

let changeData: any = {}
let changeTimeout: any

export default class JJ_Table<RecordType extends Object & { id: string } = any, SourceItemBody extends Object = any> extends React.Component<PageProps<RecordType, SourceItemBody>, PageState<RecordType, SourceItemBody>> implements Page<RecordType, SourceItemBody> {
  dataSourceBody: DataSourceBody<any>;


  constructor (props: PageProps<RecordType, SourceItemBody>) {
    super(props);

    const count = props.count || 20
    this.dataSourceBody = {
      from: 0,
      count,
      sourceItemBody: {},
    }

    if (props.sourceItems) {
      props.sourceItems.map(item => {
        this.dataSourceBody.sourceItemBody[item.key] = item.defaultValue
      })
    }

    this.state = {
      sourceItems: props.sourceItems,
      dataSource: {
        data: [],
        totalCount: 0,
      },
      pagination: {
        current: 1,
        pageSize: count,
        total: 0,
        pageSizeOptions: ['20', '50', '100', '200', '500'],
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: total => `總共 ${total} 條數據`,
        position: ['topRight', 'bottomRight'],
      }

    }

  }

  componentDidMount() {
    this._initData()
  }
  componentWillUnmount() {
    this.setState = () => { }
  }


  async refreshData(): Promise<void> {
    try {
      this.setState({
        isTableLoading: true,
      })
      await this._getData()
      this.setState({
        isTableLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isTableLoading: false,
      })
    }
  }
  async _initData(): Promise<void> {
    try {
      this.setState({
        isSpinLoading: true,
      })
      await this._getData()
      this.setState({
        isSpinLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
      })
    }
  }

  async _getData(): Promise<void> {

    changeTimeout && clearTimeout(changeTimeout)


    await new Promise((resolve, reject) => {
      changeTimeout = setTimeout(async () => {
        try {
          const res = await this.props.onDataSource(this.dataSourceBody)
          this.setState(state => ({
            dataSource: res,
            pagination: {
              ...state.pagination,
              total: res.totalCount,
            },
          }))
          resolve({})
        } catch (error: any) {
          console.log(error)
          reject(error)
        }


      }, 1000);
    })





  }



  render() {
    return (
      <Spin spinning={this.state.isSpinLoading}>

        <JJ_TableHeader
          title={this.props.title}
          isSearchText={this.props.isSearchText}
          onSourceItemData={(key, value) => {

            this.dataSourceBody.sourceItemBody[key] = value
            this.dataSourceBody['from'] = 0
            this.setState(state => ({
              pagination: { ...state.pagination, current: 1 }
            }), () => this.refreshData())

          }}
          onSearchText={(value) => {
            this.dataSourceBody['searchText'] = value
            this.dataSourceBody['from'] = 0
            this.setState(state => ({
              pagination: { ...state.pagination, current: 1 }
            }), () => this.refreshData())
          }}
          sourceItems={this.state.sourceItems}
        />

        <Table<RecordType>
          size="small"
          columns={this.props.columns}
          loading={this.state.isTableLoading}
          dataSource={
            this.state.dataSource.data.map(item => ({ ...item, key: item.id }))
          }
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter) => {
            const { current = 1, pageSize = 10 } = pagination
            this.dataSourceBody.from = (current - 1) * pageSize
            this.dataSourceBody.count = pageSize
            this.setState(state => ({
              pagination,
            }), () => this.refreshData())
          }}
          expandable={this.props.expandable}

          {...this.props.tableProps || {}}
          key={this.props.tableKey}
        />
      </Spin>
    )
  }

}





// const truncateDecimal = (value: number, decimalPlaces: number): string => {
//     const stringValue = value.toString();
//     const decimalIndex = stringValue.indexOf('.');

//     if (decimalIndex === -1) {
//         // 没有小数部分
//         return stringValue;
//     }

//     const truncatedValue = stringValue.substring(0, decimalIndex + decimalPlaces + 1);

//     return truncatedValue;
// }

// export const formatNumber = (value?: number, decimalPlaces: number = 0): string => {
//     if (value === undefined) {
//         return "";
//     }

//     const truncatedValue = truncateDecimal(value, decimalPlaces);

//     const [integerPart, decimalPart] = truncatedValue.split('.');

//     let formattedValue = integerPart;

//     if (decimalPlaces > 0) {
//         formattedValue += '.' + (decimalPart || '0').slice(0, decimalPlaces);
//     }

//     return formattedValue;
// }

export function formatNumber(value?: number, decimalPlaces: number = 0, forceDecimal: boolean = true): string {
    if (!value) {
        return '';
    }

    const stringValue = value.toString();
    const decimalIndex = stringValue.indexOf('.');

    let truncatedValue = stringValue;

    if (decimalIndex !== -1) {
        truncatedValue = stringValue.substring(0, decimalIndex + decimalPlaces + 1);
    }

    const [integerPart, decimalPart] = truncatedValue.split('.');

    let formattedValue = integerPart;

    if (decimalPlaces > 0) {
        if (decimalPart === undefined) {
            formattedValue += '.' + '0'.repeat(decimalPlaces);
        } else {
            formattedValue += '.' + (decimalPart + '000').slice(0, decimalPlaces);
        }
    } else if (forceDecimal) {
        // 如果 forceDecimal 为 true，即使没有小数部分也会显示小数点
        formattedValue += '.';
    }

    return formattedValue;
}



// 示例用法
const result1 = formatNumber(1, 1);      // 输出: "1.0"
const result2 = formatNumber(1.37, 4,true);    // 输出: "1.3"
const result3 = formatNumber(1, 2,false);      // 输出: "1.00"
const result4 = formatNumber(1.3, 2);    // 输出: "1.30"
const result5 = formatNumber(1.6633, 0); // 输出: "1.66"
const result6 = formatNumber(1.88888, 2);// 输出: "1.88"

console.log('result1', result1)
console.log('result2', result2)
console.log('result3', result3)
console.log('result4', result4)
console.log('result5', result5)
console.log('result6', result6)
import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import api, { GetProductBrandRes, GetProductBrandListParameters, GetUserParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { getProductBrandColumns, ProductBrandColumnData } from '../../table-columns/ProductBrand';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';

const { confirm } = Modal;

interface PageState {
  initLoading: boolean
  isSpinLoading: boolean
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetProductBrandRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class ProductBrandList extends React.Component<PageProps, PageState> implements Page {


  params: {
    [x: string]: any
  } = {}

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      initLoading: true,
    }
  }
  tableRef?: JJ_Table<ProductBrandColumnData> | null
  componentDidMount() {
    this._initData()
  }




  _getProductBrandList = async (params: GetProductBrandListParameters) => {
    const res = await api.GetProductBrandList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _getUser = async (params: GetUserParameters) => {
    const res = await api.GetUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true,
        initLoading: true
      })

      this.setState({
        isSpinLoading: false,
        initLoading: false,
      })
    } catch (error: any) {
      this.setState({
        isSpinLoading: false,
        initLoading: false
      })
    }
  }
  render() {

    return (
      <div id="ProductBrandList"
      >

        <Spin spinning={this.state.initLoading}>
          {!this.state.initLoading && (
            <Spin spinning={this.state.isSpinLoading}>

              <JJ_Table<ProductBrandColumnData, {
                isAscend?: 'true' | 'false'
              }>
                ref={ref => this.tableRef = ref}
                isSearchText={true}
                sourceItems={
                  tool.route.getSearchParams({
                    search: this.props.location.search,
                    sourceItems: [
                      {
                        type: 'select',
                        defaultValue: 'true',
                        key: 'isAscend',
                        span: 4,
                        options: [
                          {
                            value: 'true',
                            name: '升序',
                            disabled: false,
                          },
                          {
                            value: 'false',
                            name: '降序',
                            disabled: false,
                          },
                        ]
                      },

                    ]
                  })

                }
                columns={getProductBrandColumns({
                  user: this.props.user,
                })}
                tableProps={{
                  scroll: {
                    x: getProductBrandColumns(
                      {
                        user: this.props.user,
                      }
                    ).reduce((pv, cv) => pv + (Number(cv.width || 0)), 0),
                  }
                }}
                title={'產品品牌列表'}
                onDataSource={async (body) => {
                  const { sourceItemBody, ...params } = body
                  this.params = { ...params }

                  if (sourceItemBody) {
                    if (sourceItemBody.isAscend) {
                      this.params.isAscend = sourceItemBody.isAscend === 'true' ? true : false
                    }

                  }

                  const res = await this._getProductBrandList({
                    ...this.params,
                  })

                  tool.route.pushParamsObjHistory(this.params, this.props.history)

                  return {
                    data: res.data,
                    totalCount: res.totalCount,
                  }
                }}
              />

            </Spin>

          )}
        </Spin>

      </div>
    )
  }

}
export default connector(ProductBrandList)

